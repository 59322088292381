import React, { useEffect, useRef, useState } from "react";
import { supabase } from '../supabaseClient';


function ClassCard(props) {
    const product = props.product;
    const [membercontacts, setmemberContacts] = useState([]);
    const [groupname, setgroupname] = useState("");
  
    useEffect(() => {
        const handledegreeChange = async (e) => {
            try {
              const { data, error } = await supabase
                .from("allset")
                .select("*")
                .eq('id', product)
                .limit(1)
              if (error) throw error;
              if (data != null) {
                setmemberContacts(data);
        
              }
            } catch (error) {
    
            }
            // var allsetresponse = await supabase
    //         .from("allset")

    //    .select("*")
    //    .eq('search_name', `${seachname}`)
    //      .single();   
        };
      
        handledegreeChange(product);
      }, [product]);
    return (
       <div>
        {membercontacts.map((products) => (
            <>
     <p class="mb-3 font-bold text-center text-sm text-gray-700 md:mb-4 md:text-base">{products.name}</p>
     <p class="mb-3 text-center text-sm text-gray-500 md:mb-4 md:text-base">{products.class}</p>
        </>
          ))}
          </div>
    )
}

export default ClassCard;