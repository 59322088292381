import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../API/firebase";
import { supabase } from '../supabaseClient';
import { Scrollbar } from "smooth-scrollbar-react";
import ProgressBar from "@ramonak/react-progress-bar";
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';
import ClassCard from '../components/class_card';
import CircularProgress from "@mui/material/CircularProgress";
import ImageCard from '../components/image_card';






// import { Progress } from 'flowbite-react';
let useClickOutside = (handler) => {
  let domNode = useRef();

  // useEffect(() => {
  //   let maybeHandler = (event) => {
  //     if (!domNode.current.contains(event.target)) {
  //       handler();
  //     }
  //   };

  //   document.addEventListener("mousedown", maybeHandler);

  //   return () => {
  //     document.removeEventListener("mousedown", maybeHandler);
  //   };
  // });

  return domNode;
};
function JoinSchool() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let parms = useParams();
  const [count, setCount] = useState(parms.counts);
  const [schoolid, setschoolid] = useState(parms.id);
  const [schooltype, setschooltype] = useState(parms.status);
  const [departmentid, setdepartmentid] = useState(0);
  const [ firstName, setFirstName ] = useState(parms.name);
  const [departmentname, setDepartmentname] = useState('');

  const [degreename, setDegreename] = useState('');
  const [graduatetype, setGradutetype] = useState("Graduate");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownyrOpen, setDropdownyrOpen] = useState(false);


 
  const [contacts, setContacts] = useState([]);
  const [primary, setPrimary] = useState([]);
  const [secondary, setSecondary] = useState([]);
  const [uni, setUni] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredContacts, setFilteredContacts] = useState([]);

  const [degreecontacts, setdegreeContacts] = useState([]);
  const [degreesearch, setdegreeSearch] = useState("");
  const [degreefilteredContacts, setdegreeFilteredContacts] = useState([]);

  const [departmentcontacts, setdepartmentContacts] = useState([]);
  const [departmentsearch, setdepartmentSearch] = useState("");
  const [departmentfilteredContacts, setdepartmentFilteredContacts] = useState([]);


  const [membercontacts, setmemberContacts] = useState([]);
  const [membersearch, setmemberSearch] = useState("");
  const [memberfilteredContacts, setmemberFilteredContacts] = useState([]);

  const [ name, setName ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ products, setProducts] = useState([]);
  const [year, setyear] = useState(0);
  const [seachname, setsearchname] = useState('');
  const [email, setemail] = useState('');
  const [phone, setphone] = useState('');

  const [isavailabe, setisavailabe] = useState(true);
  const [isjoined, setjisoined] = useState(null);
  const [otp, setotp] = useState(0);
  const [isemail, setisemail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isphone, setisphone] = useState(true);

  const [isemailotp, setisemailotp] = useState(false);
  const [isphoneotp, setisphoneotp] = useState(false);

  const [isemailotpfill, setisemailotpfill] = useState(false);
  const [isphoneotpfill, setisphoneotpfill] = useState(false);
  const [getingdata, setgetingdata] = useState(true);


  // const [userid, setuserid] = useState(0);
  const [useruid, setuseruid] = useState(0);
  const [percentage, setpercentage] = useState(0);

  const emailRef = useRef();
  const otpRef = useRef();
  const phoneotpRef = useRef();
  const phoneRef = useRef();

  const passwordRef = useRef();
  const firstnameRef = useRef();
  const lastnamenameRef = useRef();
  const scrollbar = useRef(null);
  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState([]);

  const [isBackButtonClicked, setBackbuttonPress] = useState(false)
 
 const login = useGoogleLogin({
  onSuccess: (codeResponse) => setUser(codeResponse),
onError: (error) => console.log('Login Failed:', error)
});

const randomNumberInRange = (max) => {
        return  Math.floor(Math.random() * max);
    };


    
useEffect(
  () => {
      if (user) {
          axios
              .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                  headers: {
                      Authorization: `Bearer ${user.access_token}`,
                      Accept: 'application/json'
                  }
              })
              .then((res) => {
                  setLoading(true);
                  handlegmailSubmit(res.data.name,res.data.email)
                  
              })
              .catch((err) => console.log(err));
      }
  },
  [ user ]
);
async function handlegmailSubmit(name,email) {
  const password = '123456';

  // Calls `signUp` function from the context
  const {data, error } = await supabase.auth.signUp({ email, password })

  if (error) {
    setLoading(false);
    alert(error)
  } else {
    // alert('success');
    await supabase.auth.getUser().then((value) => {
      if(value.data?.user) {
        gmailregister(value.data.user.id,name,email);
      } 
   }) 
    // Redirect user to Dashboard
    // history.push('/')
  }
  // @TODO: add login logic
}
// log out function to log the user out of google and set the profile array to null
  useEffect(() => emailjs.init("user_CPxhncQgw2s5e4nNwbu36"), []);   

  useEffect(() => {
    // getProducts();
    handledegreeChange(`${seachname}`)
  }, [seachname])
  const handledegreeChange = async (e) => {
    try {
      const { data, error } = await supabase
        .from("allset")
        .select("*")
        .eq('search_name', e)
        .limit(1)
      if (error) throw error;
      if (data != null) {
        setProducts(data);

      }
    } catch (error) {
    setisavailabe(false);
    }
};
useEffect(() => {
  const fetchData = async () => {
    setgetingdata(true);

    const data = await firestore .collection("alumni")
    .doc(`${schoolid}`)
    // .doc('2227')
    .collection('members').limit(6).get();
    setgetingdata(false);
    setmemberContacts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  fetchData();
}, [schoolid]);

useEffect(() => {
  setmemberFilteredContacts(
    membercontacts.filter(
      (user) =>
        user.name.toLowerCase().includes(membersearch.toLowerCase()) 
    )
  );
}, [membersearch, membercontacts]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await firestore.collection("degree")
      .orderBy("Name", "asc").get();
      setdegreeContacts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchprimaryData = async () => {
      const data = await firestore.collection("all_alumni")
      .where("status", "==", "Primary")
      .limit(4)
      .get();
      setPrimary(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchprimaryData();
  }, []);
  useEffect(() => {
    const fetchsecondaryData = async () => {
      const data = await firestore.collection("all_alumni")
      .where("status", "==", "Secondary")
      .limit(4)
      .get();
      setSecondary(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchsecondaryData();
  }, []);
  useEffect(() => {
    const fetchuniData = async () => {
      const data = await firestore.collection("all_alumni")
      .where("status", "==", "Tertiary")
      .limit(4)
      .get();
      setUni(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchuniData();
  }, []);
  useEffect(() => {
    setdegreeFilteredContacts(
      degreecontacts.filter(
        (user) =>
          user.Name.toLowerCase().includes(degreesearch.toLowerCase()) 
      )
    );
  }, [degreesearch, degreecontacts]);

  useEffect(() => {
    const fetchData = async () => {
      setgetingdata(true);
      const data = await firestore.collection("all_alumni").get();
      setgetingdata(false);
      setContacts(data.docs.map((doc) => ({ ...doc.data() })));
    };
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredContacts(
      contacts.filter(
        (user) =>
        `${user.name} ${user.city}`.toLowerCase().includes(search.toLowerCase()) 
      )
    );
  }, [search, contacts]);

  useEffect(() => {
    console.log(`${schoolid}`);
    const fetchData = async () => {
      const data = await firestore .collection("alumni")
      .doc(`${schoolid}`)
      .collection('departments').get();
      setdepartmentContacts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchData();
  }, [schoolid]);

  useEffect(() => {
    setdepartmentFilteredContacts(
      departmentcontacts.filter(
        (user) =>
          user.name.toLowerCase().includes(departmentsearch.toLowerCase()) 
      )
    );
  }, [departmentsearch, departmentcontacts]);

  async function handleSubmit(e) {

    e.preventDefault();
   

    if(isphone== true ){
      const phone = phoneRef.current.value;
     
      setisemail(false);
      setphone(phone);
      setLoading(true);
    //   var isEmailExist = await supabase
    //     .from('auth_type')
    //     .select('phone')
    //     .eq('phone',
    //         `+234${phone}`)
    //     .limit(1)
    //     .maybeSingle();
    // if (isEmailExist != null) {
    //  alert('Phone already registed')
    // } 
      if( isphoneotpfill ==false ){
      const { data, error } = await supabase.auth.signInWithOtp({
        phone: `+234${phone}`,
      })
      if (error) {
        alert(error);
        setisphoneotp(true);
        setLoading(false);
      } else {
      setisphoneotp(true);
      setisemailotp(false);
      setLoading(false);
      }
    } else  if( isphoneotpfill ==true ){
        const phone = phoneRef.current.value;
        const validphone = `+234${phone}`;
        const phonetoken = phoneotpRef.current.value;
        const { data, error } = await supabase.auth.verifyOtp({phone: validphone,token: phonetoken, type: 'sms'})
        if (error) {
          alert(error);
          setisemail(false);
          setLoading(false);
          setisphoneotp(true);
          setisemailotp(false);
          setisphoneotpfill(false);
        } else {
          // alert("success");
          await supabase.auth.getUser().then((value) => {
            if(value.data?.user) {
              phoneregister(value.data.user.id,value.data.user.id);
            } 
           }) 
          setisemail(false);
          // setLoading(false);
          setisphoneotp(false);
          setisemailotp(false);
          setisphoneotpfill(false);
    
        }
      }
    
    }
    else if(isemail == true){
      const email = emailRef.current.value;
      setisemail(true);
      setemail(email);
      if( isemailotpfill ==false ){
        sendotp(email);
        setisphoneotp(false);
        setisemailotp(true);
      }
      else if( isemailotpfill ==true ){
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        setLoading(true);
      if(otp == otpRef.current.value){
        const {data, error } = await supabase.auth.signUp({ email, password })
        if (error) {
          alert(error);
          setisemail(false);
          setLoading(false);
          setisphoneotp(false);
          setisemailotp(true);
          setisphoneotpfill(false);
          setisemailotpfill(false);

        } else {
          await supabase.auth.getUser().then((value) => {

            if(value.data?.user) {
              setLoading(true);
              register(value.data.user.id,value.data.user.id);

            } 
         }) 
        }
      }else{
        alert('opt wrong');
  
    }
      }
    }
    // @TODO: add login logic
  }

      async function gmailregister(e,name,email) {
  
        var mainresponse = await supabase.from('user_info').insert({
          'first_name': name,
          'last_name': '',
          "email": email,
          "access_token": e,
          "user_id": e,
          "auth": false,
          'auth_type': 'gmail'
        }).single();
        var alumniresponse = await supabase
            .from('user_info')
            .select()
            .eq('user_id', e)
            .single();
        // var allsetresponse = await supabase
        //      .from("allset")
        // .select("*")
        // .eq('search_name', `${seachname}`)
        //   .single();    
         await supabase.from('auth_type').insert({
          'user_id': alumniresponse.data.id,
          'type': 'gmail',
          'email': email
        }).single();
        // alert(alumniresponse.data.id);
        // setuserid(alumniresponse.data.id); 
        isjoined
        ? join(alumniresponse.data.id, email, name)
        : create(email,alumniresponse.data.id, name);
      
      }
      async function register(e,access_token) {
        const firstNameController = firstnameRef.current.value
        const lastNameController = lastnamenameRef.current.value
        const emailController = emailRef.current.value
        var mainresponse = await supabase.from('user_info').insert({
          'first_name': firstNameController,
          'last_name': lastNameController,
          "email": emailController,
          "access_token": e,
          "user_id": e,
          "auth": false,
          'auth_type': 'email'
        }).single();
        var alumniresponse = await supabase
            .from('user_info')
            .select()
            .eq('user_id', e)
            .single();
       await supabase.from('auth_type').insert({
          'user_id': alumniresponse.data.id,
          'type': 'email',
          'email': emailController
        }).single();
       
        // alert(alumniresponse.data.id);
        // setuserid(alumniresponse.data.id); 
        isjoined
        ? join(alumniresponse.data.id, emailController, firstNameController)
        : create(emailController,alumniresponse.data.id, firstNameController);
      
      }

      async function phoneregister(e,access_token) {
        const firstNameController = firstnameRef.current.value
        const lastNameController = lastnamenameRef.current.value
        const emailController = phoneRef.current.value
        var mainresponse = await supabase.from('user_info').insert({
          'first_name': firstNameController,
          'last_name': lastNameController,
          "phone": emailController,
          "access_token": e,
          "user_id": e,
          "auth": false,
          'auth_type': 'phone'
        }).single();
        var alumniresponse = await supabase
            .from('user_info')
            .select()
            .eq('user_id', e)
            .single();
        await supabase.from('auth_type').insert({
          'user_id': alumniresponse.data.id,
          'type': 'phone',
          'phone': `234${emailController}`,
        }).single();
       
        // alert(alumniresponse.data.id);
        // setuserid(alumniresponse.data.id); 
        isjoined
        ? phonejoin(alumniresponse.data.id, emailController, firstNameController)
        : phonecreate(emailController,alumniresponse.data.id, firstNameController);
      
      }
   async function phonecreate( email, useridd,  displayName)  {
       
          try {
      
        {
          var timestamp = randomNumberInRange(12345678);
          await firestore
          .collection("usersGeneralGroups")
          .doc(`${useridd}`)
          .collection("alumnigroups")
          .doc(`${timestamp}`)
          .set({
          "userId": Number(`${useridd}`),
          "isAdmin": true,
          "joined":`${timestamp}`,
          'search_name': `${seachname}`,
          'id': Number(`${timestamp}`),
          "name":  `${departmentname}`,
          "school":  `${firstName}`,
          "school_id": Number(`${schoolid}`),
          'status':  `${schooltype}`,
          'degree':  `${degreename}` ?? 'ps',
          "photo": '',
          'graduate_type':  `${graduatetype}`,
          'class': graduatetype == "Undergraduate" ? `${year} Undergraduate` : `Class of ${year}`,
          "year":  `${year}`,
      });

      await firestore
      .collection("alumni")
      .doc(`${schoolid}`)
      .collection("generalGroups")
      .doc(`${timestamp}`)
      .collection("members")
      .doc(`${useridd}`)
      .set({
        'name': `${displayName}`,
        'phone': `${email}`,
        'status': `${schooltype}`,
        "userId": `${useridd}`,
        'group_id': Number(`${timestamp}`),
        'token': 'token',
      });

      await firestore
      .collection("alumni")
      .doc(`${schoolid}`)
      .collection("members")
      .doc(`${useridd}`)
      .set({
        'name': `${displayName}`,
        'phone': `${email}`,
        'status': `${schooltype}`,
        "userId": `${useridd}`,
        'group_id': Number(`${timestamp}`),
        'token': 'token',
      });
        

              var ref = firestore
                  .collection("alumni")
                  .doc(`${schoolid}`)
                  .collection("generalGroups")
                  .doc(`${timestamp}`)
                  .set({
                    'search_name': `${seachname}`,
                    'id': Number(`${timestamp}`),
                    "name":  `${departmentname}`,
                    "school":  `${firstName}`,
                    "school_id": Number(`${schoolid}`),
                    'status':  `${schooltype}`,
                    'degree':  `${degreename}` ?? 'ps',
                    "photo": '',
                    'graduate_type':  `${graduatetype}`,
                    'class': graduatetype == "Undergraduate" ? `${year} Undergraduate` : `Class of ${year}`,
                    "year":  `${year}`,
                    "super_admin": Number(`${useridd}`),
                  })

          
                  await supabase.from('allset').insert({
                    "userId": Number(`${useridd}`),
                    'search_name': `${seachname}`,
                    'id':  Number(`${timestamp}`),
                    "name":  `${departmentname}`,
                    "school":  `${firstName}`,
                    "school_id": Number(`${schoolid}`),
                    'status':  `${schooltype}`,
                    'degree':  `${degreename}` ?? 'ps',
                    'graduate_type':  `${graduatetype}`,
                    'class': graduatetype == "Undergraduate" ? `${year} Undergraduate` : `Class of ${year}`,
                      "year":  `${year}`,
                  }).single();

                await firestore
                    .collection("alumni")
                    .doc(`${schoolid}`)
                    .set({
                      "name":  `${departmentname}`,
                      "school":  `${firstName}`,
                      "school_id": Number(`${schoolid}`),
                      'status':  `${schooltype}`,
                      'degree':  `${degreename}` ?? 'ps',
                });

              await supabase.from('user_info').update({
                "current_school_id": Number(`${schoolid}`),
                "current_group_id":  Number(`${timestamp}`),
                'current_group_name':`${seachname}`,
              }).eq(
                'id',
                useridd,
              );
              await firestore
              .collection("userschool")
              .doc("userschool")
              .collection("userinfo")
              .doc(`${useridd}`)
              .set({'user_id': `${useridd}`});
              setCount(8);
              await firestore
              .collection("alumni")
              .doc(`${schoolid}`)
              .collection("departments")
              .doc(`${departmentname.toLowerCase()}`)
              .set({
            'name': `${departmentname.toUpperCase()}`,
          });
            }
          } catch (e) {
            console.log(e);
          }
        }

        async function phonejoin(useridd, email,  displayName)  {
          var allsetresponse = await supabase
          .from("allset")
          .select("*")
          .eq('search_name', `${seachname}`)
          .single();    
          try {
          var  timestamp = allsetresponse.id;
          await firestore
          .collection("usersGeneralGroups")
          .doc(`${useridd}`)
          .collection("alumnigroups")
          .doc(`${timestamp}`)
          .set({
          "userId": Number(`${useridd}`),
          "isAdmin": true,
          "joined":`${timestamp}`,
          'search_name': `${seachname}`,
          'id': Number(`${timestamp}`),
          "name":  `${departmentname}`,
          "school":  `${firstName}`,
          "school_id": Number(`${schoolid}`),
          'status':  `${schooltype}`,
          'degree':  `${degreename}` ?? 'ps',
          "photo": '',
          'graduate_type':  `${graduatetype}`,
          'class': graduatetype == "Undergraduate" ? `${year} Undergraduate` : `Class of ${year}`,
            "year":  `${year}`,
      });

      await firestore
      .collection("alumni")
      .doc(`${schoolid}`)
      .collection("generalGroups")
      .doc(`${timestamp}`)
      .collection("members")
      .doc(`${useridd}`)
      .set({
        'name': `${displayName}`,
        'phone': `${email}`,
        'status': `${schooltype}`,
        "userId": `${useridd}`,
        'group_id': Number(`${timestamp}`),
        'token': 'token',
      });

      await firestore
      .collection("alumni")
      .doc(`${schoolid}`)
      .collection("members")
      .doc(`${useridd}`)
      .set({
        'name': `${displayName}`,
        'phone': `${email}`,
        'status': `${schooltype}`,
        "userId": `${useridd}`,
        'group_id': Number(`${timestamp}`),
        'token': 'token',
      });
        
      await supabase.from('user_info').update({
        "current_school_id": Number(`${schoolid}`),
        "current_group_id":  Number(`${timestamp}`),
        'current_group_name':`${seachname}`,
      }).eq(
        'id',
        useridd,
      );
      await firestore
      .collection("userschool")
      .doc("userschool")
      .collection("userinfo")
      .doc(`${useridd}`)
      .set({'user_id': `${useridd}`});

       
      // postwelcome(email);
      // setLoading(false);
      setCount(8);
    } catch (e) {
      console.log(e);
    }
  }
        async function create( email, useridd,  displayName)  {
          try {
        
        {
          var timestamp = randomNumberInRange(12345678);
          await firestore
          .collection("usersGeneralGroups")
          .doc(`${useridd}`)
          .collection("alumnigroups")
          .doc(`${timestamp}`)
          .set({
          "userId": Number(`${useridd}`),
          "isAdmin": true,
          "joined":`${timestamp}`,
          'search_name': `${seachname}`,
          'id': Number(`${timestamp}`),
          "name":  `${departmentname}`,
          "school":  `${firstName}`,
          "school_id": Number(`${schoolid}`),
          'status':  `${schooltype}`,
          'degree':  `${degreename}` ?? 'ps',
          "photo": '',
          'graduate_type':  `${graduatetype}`,
          'class': graduatetype == "Undergraduate" ? `${year} Undergraduate` : `Class of ${year}`,
            "year":  `${year}`,
      });

      await firestore
      .collection("alumni")
      .doc(`${schoolid}`)
      .collection("generalGroups")
      .doc(`${timestamp}`)
      .collection("members")
      .doc(`${useridd}`)
      .set({
        'name': `${displayName}`,
        'email': `${email}`,
        'status': `${schooltype}`,
        "userId": `${useridd}`,
        'group_id': Number(`${timestamp}`),
        'token': 'token',
      });

      await firestore
      .collection("alumni")
      .doc(`${schoolid}`)
      .collection("members")
      .doc(`${useridd}`)
      .set({
        'name': `${displayName}`,
        'email': `${email}`,
        'status': `${schooltype}`,
        "userId": `${useridd}`,
        'group_id': Number(`${timestamp}`),
        'token': 'token',
      });    var ref = firestore
                  .collection("alumni")
                  .doc(`${schoolid}`)
                  .collection("generalGroups")
                  .doc(`${timestamp}`)
                  .set({
                    'search_name': `${seachname}`,
                    'id': Number(`${timestamp}`),
                    "name":  `${departmentname}`,
                    "school":  `${firstName}`,
                    "school_id": Number(`${schoolid}`),
                    'status':  `${schooltype}`,
                    'degree':  `${degreename}` ?? 'ps',
                    "photo": '',
                    'graduate_type':  `${graduatetype}`,
                    'class': graduatetype == "Undergraduate" ? `${year} Undergraduate` : `Class of ${year}`,
                    "year":  `${year}`,
                    "super_admin": Number(`${useridd}`),
                  })
                  await supabase.from('allset').insert({
                    "userId": Number(`${useridd}`),
                    'search_name': `${seachname}`,
                    'id':  Number(`${timestamp}`),
                    "name":  `${departmentname}`,
                    "school":  `${firstName}`,
                    "school_id": Number(`${schoolid}`),
                    'status':  `${schooltype}`,
                    'degree':  `${degreename}` ?? 'ps',
                    'graduate_type':  `${graduatetype}`,
                    'class': graduatetype == "Undergraduate" ? `${year} Undergraduate` : `Class of ${year}`,
                      "year":  `${year}`,
                  }).single();
                await firestore
                    .collection("alumni")
                    .doc(`${schoolid}`)
                    .set({
                      "name":  `${departmentname}`,
                      "school":  `${firstName}`,
                      "school_id": Number(`${schoolid}`),
                      'status':  `${schooltype}`,
                      'degree':  `${degreename}` ?? 'ps',
                });

              await supabase.from('user_info').update({
                "current_school_id": Number(`${schoolid}`),
                "current_group_id":  Number(`${timestamp}`),
                'current_group_name':`${seachname}`,
              }).eq(
                'id',
                useridd,
              );
              await firestore
              .collection("userschool")
              .doc("userschool")
              .collection("userinfo")
              .doc(`${useridd}`)
              .set({'user_id': `${useridd}`});
              setCount(8);
              postwelcome(email);
              await firestore
              .collection("alumni")
              .doc(`${schoolid}`)
              .collection("departments")
              .doc(`${departmentname.toLowerCase()}`)
              .set({
              'name': `${departmentname.toUpperCase()}`,
              });
              // setLoading(false);
           
            }
          } catch (e) {
            console.log(e);
          }
        }

      async function join( useridd, email,  displayName)  {
           var allsetresponse = await supabase
          .from("allset")
          .select("*")
          .eq('search_name', `${seachname}`)
          .single();  
          try {
          var  timestamp = allsetresponse.id;
          await firestore
          .collection("usersGeneralGroups")
          .doc(`${useridd}`)
          .collection("alumnigroups")
          .doc(`${timestamp}`)
          .set({
          "userId": Number(`${useridd}`),
          "isAdmin": true,
          "joined":`${timestamp}`,
          'search_name': `${seachname}`,
          'id': Number(`${timestamp}`),
          "name":  `${departmentname}`,
          "school":  `${firstName}`,
          "school_id": Number(`${schoolid}`),
          'status':  `${schooltype}`,
          'degree':  `${degreename}` ?? 'ps',
          "photo": '',
          'graduate_type':  `${graduatetype}`,
          'class': graduatetype == "Undergraduate" ? `${year} Undergraduate` : `Class of ${year}`,
            "year":  `${year}`,
      });

      await firestore
      .collection("alumni")
      .doc(`${schoolid}`)
      .collection("generalGroups")
      .doc(`${timestamp}`)
      .collection("members")
      .doc(`${useridd}`)
      .set({
        'name': `${displayName}`,
        'email': `${email}`,
        'status': `${schooltype}`,
        "userId": `${useridd}`,
        'group_id': Number(`${timestamp}`),
        'token': 'token',
      });

      await firestore
      .collection("alumni")
      .doc(`${schoolid}`)
      .collection("members")
      .doc(`${useridd}`)
      .set({
        'name': `${displayName}`,
        'email': `${email}`,
        'status': `${schooltype}`,
        "userId": `${useridd}`,
        'group_id': Number(`${timestamp}`),
        'token': 'token',
      });
        
      await supabase.from('user_info').update({
        "current_school_id": Number(`${schoolid}`),
        "current_group_id":  Number(`${timestamp}`),
        'current_group_name':`${seachname}`,
      }).eq(
        'id',
        useridd,
      );
      await firestore
      .collection("userschool")
      .doc("userschool")
      .collection("userinfo")
      .doc(`${useridd}`)
      .set({'user_id': `${useridd}`});
      postwelcome(email);
      // setLoading(false);
      setCount(8);
    } catch (e) {
      console.log(e);
    }
  }
  
async function sendotp(email)  {
 setLoading(true);
 var otp = randomNumberInRange(1234567);
 setotp(otp);
  try {
    await emailjs.send('service_pp6s0jo', 'template_on6i7nw',{
      otp: otp,
      from_name: "Alumate Mail",
      reply_to: "alumatesmail@gmail.com",
      email: email,
    });
    alert("email successfully sent check inbox");
  } catch (error) {
    alert(error);
    console.log(error);
    setLoading(false);
  } finally {
    setLoading(false);
  }

  }
async function postwelcome( email)  {
  try {
    setLoading(true);
    await emailjs.send('service_pp6s0jo', 'join_group',{
      from_name: "Alumate Mail",
      email: email,
      recipient: email,
      reply_to: "alumatesmail@gmail.com",
    });
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
  }

  return (
    <>
    

   
<nav class="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
  <a href="https://alumatesweb.netlify.app/" class="flex items-center">
      {/* <img src="https://flowbite.com/docs/images/logo.svg" class="h-8 mr-3" alt="Flowbite Logo"> */}
      <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Alumates</span>
  </a>
  <div class="flex md:order-2">
      {/* <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Join ALumni</button> */}
      <button data-collapse-toggle="navbar-sticky" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
  </div>
  <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
  </div>
  </div>
</nav>

  
    { count == 8 ?(<h1></h1>):(<section class="flex flex-col items-center mx-5 mt-10">
    
    <div class="flex max-w-xl flex-col items-center pb-0 pt-5 text-center sm:pb-10 lg:pb-25 lg:pt-25 mt-15">
      
      <img src={logo} width="150" height="100" class = "mb-5 mt-10" />
      <h1 class="mb-8 text-3xl font-bold text-black sm:text-3xl md:mb-8 md:text-4xl">WELCOME TO ALUMATES</h1>
      <p class="mb-8 leading-relaxed text-gray-500 md:mb-8 xl:text-lg"> Let's connect you with all your schoolmates, from primary school through secondary school to college and beyond.</p>
      {/* <p class="mb-8 leading-relaxed text-gray-500 md:mb-8 xl:text-lg">{seachname}.</p> */}

    </div>

    
  </section>)}
  <div class = "mb-10 sm:pb-10 lg:pb-25 mx-auto max-w-screen-2  xl px-4 md:px-8">
{count == 0 ?( <ProgressBar completed='10' customLabel=" " bgColor = "#667eea"/>):
 count == 1 ?( <ProgressBar completed='20' customLabel=" " bgColor = "#667eea"/>):
 count == 2 ?( <ProgressBar completed='30' customLabel=" " bgColor = "#667eea"/>):
 count == 3 ?( <ProgressBar completed='40' customLabel=" " bgColor = "#667eea"/>):
 count == 4 ?( <ProgressBar completed='50' customLabel=" " bgColor = "#667eea"/>):
 count == 5 ?( <ProgressBar completed='60' customLabel=" " bgColor = "#667eea"/>):
 count == 6 ?( <ProgressBar completed='70' customLabel=" " bgColor = "#667eea"/>):
 count == 7 ?( <ProgressBar completed='80' customLabel=" " bgColor = "#667eea"/>):
 (<h1></h1>)}
 {count != 0 ?( <div class="flex w-full flex-col sm:flex-row sm:justify-center mt-3"> 
 <button
                  onClick={() => setCount(0,setisphone(true),setisemail(false),setisemailotp(false),setisphoneotp(false))}
                  className={` justify-centerpx-5 py-3 text-base font-semibold text-indigo-500 rounded bg-white`}>
                  Start search again
               
                </button></div>):
 (<h1></h1>)}
  {/* <div class=" flex h-4 flex-1 overflow-hidden  rounded bg-gray-200 ">
     <span class="h-full w-1/4 rounded bg-indigo-500"></span>
  </div> */}
  </div>
     {
     count == 0 ? (
       <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
       <div class=" bg-indigo-500 py-6 sm:py-8 lg:py-12 md:px-8">
       <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
         <div class="mb-10 md:mb-16">
           {/* <h2 class="mb-4 text-center text-2xl font-bold text-white md:mb-6 lg:text-3xl">Let Help you connect of your Alumni</h2> */}
           <p class="mx-auto max-w-screen-md font-bold text-center text-white text-2xl">What is the name of your school?</p>
         </div>
         <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-4 ">
       <form class="mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2"  onChange={(e) => setSearch(e.target.value)} >
           <div class="sm:col-span-2">
           <input placeholder="Search for school..."  class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
           </div>
         </form>
       </div>
      <div class="mx-auto max-w-screen-2xl px-4 md:px-8"
            style={{ maxHeight: 250, display: "flex" }}>
                 <Scrollbar
            ref={scrollbar}
            plugins={{
              overscroll: {
                effect: "bounce"
              }
            }}
          >
             {filteredContacts.length < 1  &&  getingdata == true ?(
              <>
              <div class="mx-auto max-w-screen-md font-bold text-center text-black md:text-lg mt-5">
              <CircularProgress />
               </div>
              </>
             ) : search == '' ? (
              <p class="mx-auto max-w-screen-md text-center text-white md:text-lg"></p>

             ) : (
              filteredContacts.map((img, index) => (
                 <>
         
                 <div>
                 <button  onClick={() => setFirstName(`${img.name}, ${img.city}`,setschoolid(`${img.id}`),setschooltype(`${img.status}`),img.status == "Secondary"?setCount(4,setGradutetype('Graduate')):setCount(1))} class="justify-center flex w-full  my-1 rounded-lg bg-white px-8 py-3 text-center text-sm font-semibold  text-gray-800 outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-400 focus-visible:ring active:bg-indigo-700 md:text-base">{img.name}, {img.city?.toUpperCase()}</button>
                 <hr class="h-px my-1 border-0 dark:bg-gray-700"></hr>
                </div>
                </>
               ))
             )}
          </Scrollbar>
        
         
             </div>
             { filteredContacts.length < 1  &&  getingdata == false  && search != '' ?(
              <>
               <p class="mx-auto max-w-screen-md font-bold text-center text-white md:text-lg">Opps..</p>
               <p class="mx-auto max-w-screen-md text-center text-white md:text-lg">Your Alumni is not registed</p>
               <NavLink to = {"/add-school"}>
            <div class="flex w-full flex-col sm:flex-row sm:justify-center mt-2">
                    <a  class="inline-block rounded-lg bg-indigo-400 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">Click to register your Alumni</a>
                  </div>
            </NavLink>
            
               </>
              //  <h1 className="text-center my-5">No Images Found</h1>
             ) : search == '' ? (
              <p class="mx-auto max-w-screen-md text-center text-white md:text-lg"></p>

             ) : (
              <h1></h1>
             )}
       </div>
       </div>
          </div>
        ) :count == 1 ? (
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
  <div class=" bg-indigo-500 py-6 sm:py-8 lg:py-12 md:px-8">
  <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="mb-10 md:mb-16">
      <p class="mx-auto max-w-screen-md font-bold text-center text-white text-2xl">What is the name of your Department?</p>
    </div>
    <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-4 ">
      <form class="mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2"   onChange={(e) => setdepartmentSearch(e.target.value,setDepartmentname(e.target.value))}>
      <input placeholder="Enter for department" class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
          <button onClick={() =>setCount(2)} class="inline-block rounded bg-indigo-400 px-8 py-2 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">Next</button>
      </form>
  </div>
        {departmentfilteredContacts.length < 1 ? (
          <h1 className="text-center my-5"></h1>
        ) : departmentsearch == '' ? (
          <p class="mx-auto max-w-screen-md text-center text-white md:text-lg"></p>
          ) : (
          departmentfilteredContacts.map((img, index) => (
            <>
            { index >  5 ? (
          <h1 className="text-center my-5"></h1>
        ) : (
            <div>
            <button  onClick={() => setDepartmentname(`${img.name}`,setCount(2))} class="justify-center flex w-full my-1 rounded-lg bg-white px-8 py-3 text-center text-sm font-semibold  text-gray-800 outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-400 focus-visible:ring active:bg-indigo-700 md:text-base">{img.name}</button>
            <hr class="h-px my-1 border-0 dark:bg-gray-700"></hr>
            {/* <div class="relative flex w-full flex-col rounded-lg bg-white p-4 text-center">
            <span class="text-lg font-bold text-gray-800 lg:text-xl">{img.data.name}</span> */}
           {/* </div> */}
           </div>
        )}
           </>
          ))
        )}
  </div>
  </div>
     </div>
        ):count == 2 ? (
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
  <div class=" bg-indigo-500 py-6 sm:py-8 lg:py-12 md:px-8">
  <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="mb-10 md:mb-16">
      <p class="mx-auto max-w-screen-md font-bold text-center text-white text-2xl">Search for Degree</p>
    </div>
    <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-4 ">
  <form class="mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2" onChange={(e) => setdegreeSearch(e.target.value)} >
      <div class="sm:col-span-2">
      <input placeholder="Search for degree..."  class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
      </div>
    </form>
  </div>
        {degreefilteredContacts.length < 1 ? (
          <h1 className="text-center my-5">No Degree Found</h1>
        ) : degreesearch == '' ? (
          <p class="mx-auto max-w-screen-md text-center text-white md:text-lg"></p>
        ) : (
          degreefilteredContacts.map((img, index) => (
            <>
            { index >  5 ? (
          <h1 className="text-center my-5"></h1>
        ) : (
            <div>
            <button  onClick={() => setDegreename(`${img.Name}`,setCount(3))} class="flex w-full flex-col my-1 rounded-lg bg-white px-8 py-3 text-center text-sm font-semibold  text-gray-800 outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-400 focus-visible:ring active:bg-indigo-700 md:text-base">{img.Name}</button>
            <hr class="h-px my-1 border-0 dark:bg-gray-700"></hr>
            {/* <div class="relative flex w-full flex-col rounded-lg bg-white p-4 text-center">
            <span class="text-lg font-bold text-gray-800 lg:text-xl">{img.data.name}</span> */}
           {/* </div> */}
           </div>
        )}
           </>
          ))
        )}
  </div>
  </div>
     </div>
        ):count == 3 ? (
          <div  >
          <div class=" bg-indigo-500 py-6 sm:py-8 lg:py-12 md:px-8 mx-4">
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <div class="mb-10 md:mb-16">
              
            {graduatetype == "Graduate" ? (  <h2  class="mx-auto max-w-screen-md font-bold text-center text-white text-2xl">Graduation Type</h2> ):(  <h2  class="mx-auto max-w-screen-md font-bold text-center text-white text-2xl">Admission year</h2>)}
            
            </div>
            <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-4 h-25">
            <div class="flex w-full qw sm:flex-row justify-center">
                     <button
                          style={{width : '320px'}}
                          onClick={() => setDropdownOpen(!dropdownOpen)}
                          className={`flex justify-center items-center py-3 text-base font-semibold text-indigo-500 rounded bg-white`}
                        >
                          Graduate/Undergraduate
                          <span className="pl-2">
                            <svg
                              width="12"
                              height="7"
                              viewBox="0 0 12 7"
                              className="fill-current"
                            >
                              <path d="M0.564864 0.879232C0.564864 0.808624 0.600168 0.720364 0.653125 0.667408C0.776689 0.543843 0.970861 0.543844 1.09443 0.649756L5.82517 5.09807C5.91343 5.18633 6.07229 5.18633 6.17821 5.09807L10.9089 0.649756C11.0325 0.526192 11.2267 0.543844 11.3502 0.667408C11.4738 0.790972 11.4562 0.985145 11.3326 1.10871L6.60185 5.55702C6.26647 5.85711 5.73691 5.85711 5.41917 5.55702L0.670776 1.10871C0.600168 1.0381 0.564864 0.967492 0.564864 0.879232Z" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.4719 0.229332L6.00169 4.48868L10.5171 0.24288C10.9015 -0.133119 11.4504 -0.0312785 11.7497 0.267983C12.1344 0.652758 12.0332 1.2069 11.732 1.50812L11.7197 1.52041L6.97862 5.9781C6.43509 6.46442 5.57339 6.47872 5.03222 5.96853C5.03192 5.96825 5.03252 5.96881 5.03222 5.96853L0.271144 1.50833C0.123314 1.3605 -5.04223e-08 1.15353 -3.84322e-08 0.879226C-2.88721e-08 0.660517 0.0936127 0.428074 0.253705 0.267982C0.593641 -0.0719548 1.12269 -0.0699964 1.46204 0.220873L1.4719 0.229332ZM5.41917 5.55702C5.73691 5.85711 6.26647 5.85711 6.60185 5.55702L11.3326 1.10871C11.4562 0.985145 11.4738 0.790972 11.3502 0.667408C11.2267 0.543844 11.0325 0.526192 10.9089 0.649756L6.17821 5.09807C6.07229 5.18633 5.91343 5.18633 5.82517 5.09807L1.09443 0.649756C0.970861 0.543844 0.776689 0.543843 0.653125 0.667408C0.600168 0.720364 0.564864 0.808624 0.564864 0.879232C0.564864 0.967492 0.600168 1.0381 0.670776 1.10871L5.41917 5.55702Z"
                              />
                            </svg>
                          </span>
                        </button>
                   </div>
                   {dropdownOpen == true ? (   
                   <div     className={`mx-auto max-w-screen-2xl px-4 md:px-8 my-3`} >
              
              <div>
                  
                <div className="flex w-full qw sm:flex-row justify-center">
                <div
          className="sample-container"
          style={{ maxHeight: 200, display: "flex" }}
          
        >
             
          <Scrollbar
            ref={scrollbar}
            plugins={{
              overscroll: {
                effect: "bounce"
              }
            }}
          >
            <div >
              
            <button
                    style={{width : '320px'}}
                    onClick={() => setGradutetype('Graduate',setCount(4))}
                    className={('justify-center flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
                    Graduate
             </button>   
             <button
                    style={{width : '320px'}}
                    onClick={() => setGradutetype('Undergraduate',setCount(4))}
                    className={('justify-center flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
                    Undergraduate
             </button>   
            </div>
          </Scrollbar>
        
        </div>
        
                </div>
                <div class="flex w-full qw sm:flex-row justify-center">
             </div>
              </div>
               
            </div> ):(  <h2 class="mx-auto max-w-screen-md text-center text-white md:text-lg"></h2>)}
        
           
                 
                
          </div>
          </div>
          </div>
             </div>
        ):count == 4 ? (
          <div  >
  <div class=" bg-indigo-500 py-6 sm:py-8 lg:py-12 md:px-8 mx-4">
  <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="mb-10 md:mb-16">
      
    {graduatetype == "Graduate" ? (  <h2  class="mx-auto max-w-screen-md font-bold text-center text-white text-2xl">Graduation year</h2> ):(  <h2  class="mx-auto max-w-screen-md font-bold text-center text-white text-2xl">Admission year</h2>)}
    
    </div>
    <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-4 h-25">
    <div class="flex w-full qw sm:flex-row justify-center">
             <button
                  onClick={() => setDropdownyrOpen(!dropdownyrOpen)}
                  className={`flex items-center px-5 py-3 text-base font-semibold text-indigo-500 rounded bg-white`}
                >
                  Select year
                  <span className="pl-2">
                    <svg
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      className="fill-current"
                    >
                      <path d="M0.564864 0.879232C0.564864 0.808624 0.600168 0.720364 0.653125 0.667408C0.776689 0.543843 0.970861 0.543844 1.09443 0.649756L5.82517 5.09807C5.91343 5.18633 6.07229 5.18633 6.17821 5.09807L10.9089 0.649756C11.0325 0.526192 11.2267 0.543844 11.3502 0.667408C11.4738 0.790972 11.4562 0.985145 11.3326 1.10871L6.60185 5.55702C6.26647 5.85711 5.73691 5.85711 5.41917 5.55702L0.670776 1.10871C0.600168 1.0381 0.564864 0.967492 0.564864 0.879232Z" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.4719 0.229332L6.00169 4.48868L10.5171 0.24288C10.9015 -0.133119 11.4504 -0.0312785 11.7497 0.267983C12.1344 0.652758 12.0332 1.2069 11.732 1.50812L11.7197 1.52041L6.97862 5.9781C6.43509 6.46442 5.57339 6.47872 5.03222 5.96853C5.03192 5.96825 5.03252 5.96881 5.03222 5.96853L0.271144 1.50833C0.123314 1.3605 -5.04223e-08 1.15353 -3.84322e-08 0.879226C-2.88721e-08 0.660517 0.0936127 0.428074 0.253705 0.267982C0.593641 -0.0719548 1.12269 -0.0699964 1.46204 0.220873L1.4719 0.229332ZM5.41917 5.55702C5.73691 5.85711 6.26647 5.85711 6.60185 5.55702L11.3326 1.10871C11.4562 0.985145 11.4738 0.790972 11.3502 0.667408C11.2267 0.543844 11.0325 0.526192 10.9089 0.649756L6.17821 5.09807C6.07229 5.18633 5.91343 5.18633 5.82517 5.09807L1.09443 0.649756C0.970861 0.543844 0.776689 0.543843 0.653125 0.667408C0.600168 0.720364 0.564864 0.808624 0.564864 0.879232C0.564864 0.967492 0.600168 1.0381 0.670776 1.10871L5.41917 5.55702Z"
                      />
                    </svg>
                  </span>
                </button>
           </div>
           {dropdownyrOpen == true ? (   
           <div     className={`mx-auto max-w-screen-2xl px-4 md:px-8 my-3`} >
      
      <div className="text-center">
          
        <div className="relative inline-block mb-8 text-left">
        <div
  className="sample-container"
  style={{ maxHeight: 200, display: "flex" }}
  
>
     
  <Scrollbar
    ref={scrollbar}
    plugins={{
      overscroll: {
        effect: "bounce"
      }
    }}
  >
    <div >
    <button
            onClick={() => setyear(1940,setCount(5))}
            className={(year == 1940 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1940
     </button>   
     <button
            onClick={() => setyear(1941,setCount(5))}
            className={(year == 1941 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1941
     </button>   
     <button
            onClick={() => setyear(1942,setCount(5))}
            className={(year == 1942 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1942
     </button> 
     <button
            onClick={() => setyear(1943,setCount(5))}
            className={(year == 1943 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1943
     </button> 
     <button
            onClick={() => setyear(1944,setCount(5))}
            className={(year == 1944 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1944
     </button> 
     <button
            onClick={() => setyear(1945,setCount(5))}
            className={(year == 1945 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1945
     </button> 
     <button
            onClick={() => setyear(1946,setCount(5))}
            className={(year == 1946?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1946
     </button> 
     <button
            onClick={() => setyear(1947 ,setCount(5))}
            className={(year == 1947 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1947
     </button> 
     <button
            onClick={() => setyear(1948 ,setCount(5))}
            className={(year == 1948 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1948
     </button> 
     <button
            onClick={() => setyear(1949 ,setCount(5))}
            className={(year == 1949 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1949
     </button> 
     <button
            onClick={() => setyear(1950 ,setCount(5))}
            className={(year == 1950 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1950
     </button> 
     <button
            onClick={() => setyear(1951 ,setCount(5))}
            className={(year == 1951 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1951
     </button> 
     <button
            onClick={() => setyear(1952 ,setCount(5))}
            className={(year == 1952 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1952
     </button> 
     <button
            onClick={() => setyear(1953 ,setCount(5))}
            className={(year == 1953 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1953
     </button> 
     <button
            onClick={() => setyear(1954 ,setCount(5))}
            className={(year == 1954?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1954
     </button> 
     <button
            onClick={() => setyear(1955 ,setCount(5))}
            className={(year == 1955 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1955
     </button> 
     <button
            onClick={() => setyear(1956 ,setCount(5))}
            className={(year == 1956 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1956
     </button> 
     <button
            onClick={() => setyear(1957 ,setCount(5))}
            className={(year == 1957 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1957
     </button> 
     <button
            onClick={() => setyear(1958 ,setCount(5))}
            className={(year == 1958 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1958
     </button> 
     <button
            onClick={() => setyear(1959 ,setCount(5))}
            className={(year == 1959 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1959
     </button> 
     <button
            onClick={() => setyear(1960 ,setCount(5))}
            className={(year == 1960 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1960
     </button> 
     <button
            onClick={() => setyear(1961 ,setCount(5))}
            className={(year == 1961 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1961
     </button> 
     <button
            onClick={() => setyear(1962 ,setCount(5))}
            className={(year == 1962 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1962
     </button> 
     <button
            onClick={() => setyear(1963 ,setCount(5))}
            className={(year == 1963 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1963
     </button> 
     <button
            onClick={() => setyear(1964 ,setCount(5))}
            className={(year == 1964 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1964
     </button> 
     <button
            onClick={() => setyear(1965 ,setCount(5))}
            className={(year == 1965 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1965
     </button>     <button
            onClick={() => setyear(1966 ,setCount(5))}
            className={(year == 1966 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1966
     </button>     <button
            onClick={() => setyear(1967 ,setCount(5))}
            className={(year == 1967 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1967
     </button>     <button
            onClick={() => setyear(1968 ,setCount(5))}
            className={(year == 1968 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1968
     </button> 
     <button
            onClick={() => setyear(1969 ,setCount(5))}
            className={(year == 1969 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1969
     </button>    
      <button
            onClick={() => setyear(1970 ,setCount(5))}
            className={(year == 1970 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1970
     </button>
     <button
            onClick={() => setyear(1971 ,setCount(5))}
            className={(year == 1971 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1971
     </button> 
     <button
            onClick={() => setyear(1972 ,setCount(5))}
            className={(year == 1972 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1972
     </button> 
     <button
            onClick={() => setyear(1973 ,setCount(5))}
            className={(year == 1973 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1973
     </button>  
     <button
            onClick={() => setyear(1974 ,setCount(5))}
            className={(year == 1974 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1974
     </button> 
     <button
            onClick={() => setyear(1975 ,setCount(5))}
            className={(year == 1975 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1975
     </button> 
     <button
            onClick={() => setyear(1976 ,setCount(5))}
            className={(year == 1976 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1976
     </button> 
     <button
            onClick={() => setyear(1977 ,setCount(5))}
            className={(year == 1977 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1977
     </button> 
     <button
            onClick={() => setyear(1978 ,setCount(5))}
            className={(year == 1978 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1978
     </button> 
     <button
            onClick={() => setyear(1979 ,setCount(5))}
            className={(year == 1979 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1979
     </button> 
     <button
            onClick={() => setyear(1980 ,setCount(5))}
            className={(year == 1980 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1980
     </button> 
     <button
            onClick={() => setyear(1981 ,setCount(5))}
            className={(year == 1981 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1981
     </button>      <button
            onClick={() => setyear(1982 ,setCount(5))}
            className={(year == 1982?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1982
     </button>      <button
            onClick={() => setyear(1983 ,setCount(5))}
            className={(year == 1983 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1983
     </button>      <button
            onClick={() => setyear(1984 ,setCount(5))}
            className={(year == 1984 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1984
     </button>      <button
            onClick={() => setyear(1985 ,setCount(5))}
            className={(year == 1985 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1985
     </button>      <button
            onClick={() => setyear(1986 ,setCount(5))}
            className={(year == 1986 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1986
     </button>      <button
            onClick={() => setyear(1987 ,setCount(5))}
            className={(year == 1987 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1987
     </button>      <button
            onClick={() => setyear(1989 ,setCount(5))}
            className={(year == 1989 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1989
     </button> 
     <button
            onClick={() => setyear(1990 ,setCount(5))}
            className={(year == 1990 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1990
     </button> 
     <button
            onClick={() => setyear(1991 ,setCount(5))}
            className={(year == 1991 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1991
     </button> <button
            onClick={() => setyear(1992 ,setCount(5))}
            className={(year == 1992 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1992
     </button> <button
            onClick={() => setyear(1993 ,setCount(5))}
            className={(year == 1993 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1993
     </button> <button
            onClick={() => setyear(1994 ,setCount(5))}
            className={(year == 1994 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1994
     </button> <button
            onClick={() => setyear(1995 ,setCount(5))}
            className={(year == 1995 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1995
     </button> <button
            onClick={() => setyear(1996 ,setCount(5))}
            className={(year == 1996 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1996
     </button> <button
            onClick={() => setyear(1997 ,setCount(5))}
            className={(year == 1997 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1997
     </button> <button
            onClick={() => setyear(1998 ,setCount(5))}
            className={(year == 1998 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1998
     </button> <button
            onClick={() => setyear(1999 ,setCount(5))}
            className={(year == 1999 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            1999
     </button> 
     <button
            onClick={() => setyear(2000 ,setCount(5))}
            className={(year == 2000 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2000
     </button> 
     <button
            onClick={() => setyear(2001 ,setCount(5))}
            className={(year == 2001 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2001
     </button> <button
            onClick={() => setyear(2002 ,setCount(5))}
            className={(year == 2002 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2002
     </button> <button
            onClick={() => setyear(2003 ,setCount(5))}
            className={(year == 2003 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2003
     </button> <button
            onClick={() => setyear(2004 ,setCount(5))}
            className={(year == 2004 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2004
     </button> <button
            onClick={() => setyear(2005 ,setCount(5))}
            className={(year == 2005 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2005
     </button> <button
            onClick={() => setyear(2006 ,setCount(5))}
            className={(year == 2006 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2006
     </button> <button
            onClick={() => setyear(2007 ,setCount(5))}
            className={(year == 2007 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2007
     </button> <button
            onClick={() => setyear(2008 ,setCount(5))}
            className={(year == 2008 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2008
     </button>
     <button
            onClick={() => setyear(2009,setCount(5))}
            className={(year == 2009 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2009
     </button>
     <button
            onClick={() => setyear(2010 ,setCount(5))}
            className={(year == 2010 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2010
     </button>
     <button
            onClick={() => setyear(2011 ,setCount(5))}
            className={(year == 2011 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2011
     </button> <button
            onClick={() => setyear(2012 ,setCount(5))}
            className={(year == 2012 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2012
     </button> <button
            onClick={() => setyear(2013 ,setCount(5))}
            className={(year == 2013 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2013
     </button> <button
            onClick={() => setyear(2014 ,setCount(5))}
            className={(year == 2014 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2014
     </button> <button
            onClick={() => setyear(2015 ,setCount(5))}
            className={(year == 2015 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2015
     </button> <button
            onClick={() => setyear(2016 ,setCount(5))}
            className={(year == 2016 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2016
     </button> <button
            onClick={() => setyear(2017 ,setCount(5))}
            className={(year == 2017 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2017
     </button> <button
            onClick={() => setyear(2018 ,setCount(5))}
            className={(year == 2018 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2018
     </button> <button
            onClick={() => setyear(2019 ,setCount(5))}
            className={(year == 2019 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2019
     </button>
     <button
            onClick={() => setyear(2020 ,setCount(5))}
            className={(year == 2020 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2020
     </button>
     <button
            onClick={() => setyear(2021 ,setCount(5))}
            className={(year == 2021 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2021
     </button>   <button
            onClick={() => setyear(2022 ,setCount(5))}
            className={(year == 2022 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2022
     </button>   <button
            onClick={() => setyear(2023 ,setCount(5))}
            className={(year == 2023 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2023
     </button>   <button
            onClick={() => setyear(2024 ,setCount(5))}
            className={(year == 2024 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2024
     </button>   <button
            onClick={() => setyear(2025 ,setCount(5))}
            className={(year == 2025 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2025
     </button>   <button
            onClick={() => setyear(2026 ,setCount(5))}
            className={(year == 2026 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2026
     </button>   <button
            onClick={() => setyear(2027 ,setCount(5))}
            className={(year == 2027 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2027
     </button>   <button
            onClick={() => setyear(2028 ,setCount(5))}
            className={(year == 2028 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2028
     </button>   <button
            onClick={() => setyear(2029 ,setCount(5))}
            className={(year == 2029 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2029
     </button>
     <button
            onClick={() => setyear(2030 ,setCount(5))}
            className={(year == 2030 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2030
     </button>
     <button
            onClick={() => setyear(2031 ,setCount(5))}
            className={(year == 2031 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2031
     </button>  <button
            onClick={() => setyear(2032 ,setCount(5))}
            className={(year == 2032 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2032
     </button>  <button
            onClick={() => setyear(2033 ,setCount(5))}
            className={(year == 2033 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2033
     </button>  <button
            onClick={() => setyear(2034 ,setCount(5))}
            className={(year == 2034 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2034
     </button>  <button
            onClick={() => setyear(2035 ,setCount(5))}
            className={(year == 2035 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2035
     </button>  <button
            onClick={() => setyear(2036 ,setCount(5))}
            className={(year == 2036 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2036
     </button>  <button
            onClick={() => setyear(2037 ,setCount(5))}
            className={(year == 2037 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2037
     </button>  <button
            onClick={() => setyear(2038 ,setCount(5))}
            className={(year == 2038 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2038
     </button>  <button
            onClick={() => setyear(2039 ,setCount(5))}
            className={(year == 2039 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2039
     </button>
     <button
            onClick={() => setyear(2040 ,setCount(5))}
            className={(year == 2040 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2040
     </button>
     <button
            onClick={() => setyear(2041 ,setCount(5))}
            className={(year == 2041 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2041
     </button>
     <button
            onClick={() => setyear(2042 ,setCount(5))}
            className={(year == 2042 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2042
     </button>   <button
            onClick={() => setyear(2043 ,setCount(5))}
            className={(year == 2043 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2043
     </button>   <button
            onClick={() => setyear(2044 ,setCount(5))}
            className={(year == 2044 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2044
     </button>   <button
            onClick={() => setyear(2045 ,setCount(5))}
            className={(year == 2045 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2045
     </button>   <button
            onClick={() => setyear(2046 ,setCount(5))}
            className={(year == 2046 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2046
     </button>   <button
            onClick={() => setyear(2047 ,setCount(5))}
            className={(year == 2047 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2047
     </button>   <button
            onClick={() => setyear(2048 ,setCount(5))}
            className={(year == 2048 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2048
     </button>   <button
            onClick={() => setyear(2049 ,setCount(5))}
            className={(year == 2049 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2049
     </button> 
       <button
            onClick={() => setyear(2050 ,setCount(5))}
            className={(year == 2050 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2050
     </button>
     <button
            onClick={() => setyear(2051 ,setCount(5))}
            className={(year == 2051 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2051
     </button>   <button
            onClick={() => setyear(2052 ,setCount(5))}
            className={(year == 2052 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2052
     </button>   <button
            onClick={() => setyear(2053 ,setCount(5))}
            className={(year == 2053 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2053
     </button>   <button
            onClick={() => setyear(2054 ,setCount(5))}
            className={(year == 2054 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2054
     </button>   <button
            onClick={() => setyear(2055 ,setCount(5))}
            className={(year == 2055 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2055
     </button>   <button
            onClick={() => setyear(2056 ,setCount(5))}
            className={(year == 2056 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2056
     </button>   <button
            onClick={() => setyear(2057 ,setCount(5))}
            className={(year == 2057 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2057
     </button>   <button
            onClick={() => setyear(2058 ,setCount(5))}
            className={(year == 2058 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2058
     </button>   <button
            onClick={() => setyear(2059 ,setCount(5))}
            className={(year == 2059 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2059
     </button>  
      <button
            onClick={() => setyear(2060 ,setCount(5))}
            className={(year == 2060 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2060
     </button> 
     <button
            onClick={() => setyear(2061 ,setCount(5))}
            className={(year == 2061 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2061
     </button>    <button
            onClick={() => setyear(2062 ,setCount(5))}
            className={(year == 2062 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2062
     </button>    <button
            onClick={() => setyear(2063 ,setCount(5))}
            className={(year == 2063 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2063
     </button>    <button
            onClick={() => setyear(2064 ,setCount(5))}
            className={(year == 2064 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2064
     </button>    <button
            onClick={() => setyear(2065 ,setCount(5))}
            className={(year == 2065 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2065
     </button>    <button
            onClick={() => setyear(2066 ,setCount(5))}
            className={(year == 2066 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2066
     </button>    <button
            onClick={() => setyear(2067 ,setCount(5))}
            className={(year == 2067 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2067
     </button>    <button
            onClick={() => setyear(2068 ,setCount(5))}
            className={(year == 2068 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2068
     </button>  
     <button
            onClick={() => setyear(2069 ,setCount(5))}
            className={(year == 2069 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2069
     </button>  
     <button
            onClick={() => setyear(2070 ,setCount(5))}
            className={(year == 2070 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2070
     </button> 
     <button
            onClick={() => setyear(2071 ,setCount(5))}
            className={(year == 2071 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2071
     </button> <button
            onClick={() => setyear(2072 ,setCount(5))}
            className={(year == 2072 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2072
     </button> <button
            onClick={() => setyear(2073 ,setCount(5))}
            className={(year == 2073 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2073
     </button> <button
            onClick={() => setyear(2074 ,setCount(5))}
            className={(year == 2074 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2074
     </button> <button
            onClick={() => setyear(2075 ,setCount(5))}
            className={(year == 2075 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2075
     </button> <button
            onClick={() => setyear(2076 ,setCount(5))}
            className={(year == 2076 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2076
     </button> <button
            onClick={() => setyear(2077 ,setCount(5))}
            className={(year == 2077 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2077
     </button> <button
            onClick={() => setyear(2078 ,setCount(5))}
            className={(year == 2078 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2078
     </button>
      <button
            onClick={() => setyear(2079 ,setCount(5))}
            className={(year == 2079 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2079
     </button> 
     <button
            onClick={() => setyear(2080 ,setCount(5))}
            className={(year == 2080 ?  'flex items-center px-20 py-2 text-base font-semibold bg-indigo-400 rounded text-white my-2' : 'flex items-center px-20 py-2 text-base font-semibold text-indigo-500 rounded bg-white my-2')}>
            2080
     </button> 
  
    </div>
  </Scrollbar>

</div>

        </div>
        <div class="flex w-full qw sm:flex-row justify-center">
     </div>
      </div>
       
    </div> ):(  <h2 class="mx-auto max-w-screen-md text-center text-white md:text-lg"></h2>)}

   
         
        
  </div>
  </div>
  </div>
     </div>
        ):count == 5 ? (
          <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
  <div class=" bg-indigo-500 py-6 sm:py-8 lg:py-12 md:px-8">
  {schooltype == "Secondary" ? (   
  <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
  <div class="mb-10 md:mb-16">
      <h2 class="mx-auto max-w-screen-md text-center text-white md:text-lg font-bold">School Details</h2>
      <p class="mx-auto max-w-screen-md text-center text-white md:text-lg">Please confirm you are correct and proceed</p>
    </div>
    <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-4 ">
    <div class="flex items-start justify-between gap-2 px-2 my-3">
          <div class="flex flex-col">
            <a  class="text-lg  text-white transition duration-100 hover:text-gray-500 lg:text-xl">School name: </a>
          </div>
          <div class="flex flex-col items-end">
            <span class="font-bold text-white lg:text-lg">{firstName}</span>
          </div>
         </div>
         <div class="flex items-start justify-between gap-2 px-2 my-3">
          <div class="flex flex-col">
          {graduatetype == "Graduate" ? (  <a  class="text-lg  text-white transition duration-100 hover:text-gray-500 lg:text-xl">Year of Graduate: </a>) : (  <a  class="text-lg  text-white transition duration-100 hover:text-gray-500 lg:text-xl">Year of Admission</a>)}
          </div>

          <div class="flex flex-col items-end">
          <span class="font-bold text-white lg:text-lg">{year}</span>
          </div>
         </div>
         <div class="flex w-ful flex-row justify-center mt-8">
               <button
                  onClick={() => setCount(6,setsearchname(`${schoolid}NULL${year}`))}
                  className={`flex items-center px-5 py-3 text-base font-semibold text-indigo-500 rounded bg-white`}
                >
                  Proceed
               
                </button>
                </div>
  </div>
    </div>): (<div class="mx-auto max-w-screen-2xl px-4 md:px-8">
  <div class="mb-10 md:mb-16">
      <h2 class="mb-4 text-center text-2xl font-bold text-white md:mb-6 lg:text-3xl">School Details</h2>
      <p class="mx-auto max-w-screen-md text-center text-white md:text-lg">Please confirm you are correct and proceed</p>
    </div>
    <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-4 ">
    <div class="flex items-start justify-between gap-2 px-2 my-3">
          <div class="flex flex-col">
            <a  class="text-lg  text-white transition duration-100 hover:text-gray-500 lg:text-xl">School name: </a>
          </div>

          <div class="flex flex-col items-end">
            <span class="font-bold text-white lg:text-lg">{firstName}</span>
          </div>
        </div>
        <div class="flex items-start justify-between gap-2 px-2 my-3">
          <div class="flex flex-col">
            <a  class="text-lg  text-white transition duration-100 hover:text-gray-500 lg:text-xl">Department: </a>
          </div>

          <div class="flex flex-col items-end">
            <span class="font-bold text-white lg:text-lg">{departmentname}</span>
          </div>
        </div>
        <div class="flex items-start justify-between gap-2 px-2 my-3">
          <div class="flex flex-col">
            <a  class="text-lg  text-white transition duration-100 hover:text-gray-500 lg:text-xl">Degree: </a>
          </div>

          <div class="flex flex-col items-end">
            <span class="font-bold text-white lg:text-lg">{degreename}</span>
          </div>
        </div>
        <div class="flex items-start justify-between gap-2 px-2 my-3">
          <div class="flex flex-col">
          {graduatetype == "Graduate" ? (  <a  class="text-lg  text-white transition duration-100 hover:text-gray-500 lg:text-xl">Year of Graduate: </a>) : (  <a  class="text-lg  text-white transition duration-100 hover:text-gray-500 lg:text-xl">Year of Admission: </a>)}
          </div>

          <div class="flex flex-col items-end">
            <span class="font-bold text-white lg:text-lg">{year}</span>
          </div>
        </div>
        <div class="flex w-ful flex-row justify-center mt-8">
        <button
                  onClick={() => setCount(6,setsearchname(`${schoolid}${degreename}${departmentname}${graduatetype}${year}`.toUpperCase().replace(/ /g, '')))}
                  className={`flex items-center px-5 py-3 text-base font-semibold text-indigo-500 rounded bg-white`}
                >
                  Proceed
               
                </button>
                </div>
  </div>
    </div>)};
  </div>
     </div>
        ):count == 6 ? (
             <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
             <div class=" py-6 sm:py-8 lg:py-12 md:px-8">
             <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
             <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-4 ">
            {  getingdata == true && products.length == null ? 
            (
                 <h1></h1>         )  :products.length < 1  ? 
            (
                 <div class="mx-auto max-w-screen-2xl">
                 <div class="  bg-gray-200 py-6 sm:py-8 lg:py-12 md:px-8">
                 <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
                   <div class="mb-10 md:mb-16">
                     <h2 class="mb-4 text-center text-2xl font-bold text-indigo-500 md:mb-6 lg:text-4xl">Oops..</h2>
                     <p class="mx-auto max-w-screen-md text-center text-indigo-400 md:text-lg">Your set is not registed</p>
               
                   </div>
                   <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-4 ">
                   <div class="flex w-ful flex-row justify-center mt-8">
           <button
                  onClick={() => setCount(7,setjisoined(false))}
                  className={`flex items-center px-5 py-3 text-base font-semibold text-white rounded bg-indigo-500`}
                >
                  Create Set
               
                </button>
                </div>
                 </div>
                   
                 </div>
                 </div>
                              </div>           )  : (
              products.map((img, index) => (
                 <>
                
                <div class="mx-auto max-w-screen-2xl">
  <div class=" bg-gray-200 py-6 sm:py-8 lg:py-12 md:px-8">
  <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="mb-10 md:mb-16">
      <h2 class="mb-4 text-center text-2xl font-bold text-indigo-500 md:mb-6 lg:text-4xl">{firstName}</h2>
      <p class="mx-auto max-w-screen-md text-center text-indigo-400 md:text-lg">{img.class}</p>
    </div>
    <div class="mx-auto max-w-screen-2xl px-4 md:px-3 my-2 ">
    <div class="flex w-ful flex-row justify-center mt-8">
           <button
                  onClick={() => setCount(7,setjisoined(true),setdepartmentid(img.id))}
                  className={`flex items-center px-5 py-3 text-base font-semibold text-white rounded bg-indigo-500`}
                >
                 Join Set
                </button>
                </div>
  </div>
    
  </div>
  </div>
               </div>
          
                </>
               ))
             )}

     </div>
     <hr class="hr my-5" />
     <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
         <div class="mb-10 md:mb-16">
           <h2 class="mb-4 text-center text-2xl font-bold text-black  md:mb-6 lg:text-2xl">MEET YOUR CLASSMATES</h2>
         </div>
        
         <div class="mx-auto max-w-screen-2xl px-4 md:px-8 my-6 ">
       <form class="mx-auto grid max-w-screen-md gap-4 sm:grid-cols-2"  onChange={(e) => setmemberSearch(e.target.value)} >
           <div class="sm:col-span-2">
           <input placeholder="Search for schoolmates"  class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
           </div>
         </form>
       </div>
         <div class="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 lg:gap-8">
             {memberfilteredContacts.length < 1 ? (
               <h1 className="text-center my-5">No schoolmate found</h1>
             ) : 
             (
              memberfilteredContacts.map((img, index) => (
                 <>
            
            <div class="flex flex-col items-center rounded-lg bg-gray-100 p-4 lg:p-8">
              {/* <div class="mb-2 h-24 w-24 overflow-hidden rounded-full bg-gray-200 shadow-lg md:mb-4 md:h-32 md:w-32">
                <img src="https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?auto=format&q=75&fit=crop&w=256" loading="lazy" alt="Photo by Matheus Ferrero" class="h-full w-full object-cover object-center" />
              </div> */}
             <ImageCard product={img.user_id??"0"} /> 
              <div>
                <div class="text-center font-bold text-indigo-500 md:text-lg">{img.name}</div>
                <p class="mb-3 text-center text-sm text-gray-500 md:mb-4 md:text-base">{img.profession}</p>
                <ClassCard product={img.group_id??"123456"} />

              </div>
            </div>
                </>
               ))
             )}
             </div>
       </div>
    
    </div>
      </div>
     </div>
        ):count == 7 ? (
          <>
          <div class="bg-white py-6 sm:py-8 lg:py-12">
             <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
            <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-3xl">Create your accout to meet your Schoolmates</h2>
          <div class="mx-auto max-w-lg rounded-lg border">
          <div class="flex flex-col gap-4 p-4 md:p-8" >
            <form onSubmit={handleSubmit} class="flex flex-col gap-4" >
            
              <div>
            <label for="first-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">First Name</label>
            <input name="first-name" ref={firstnameRef} class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
            </div>

             <div>
               <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Last Name</label>
               <input name="last-name" ref={lastnamenameRef} class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
               </div>
               {isemail == true ?(   <div class="flex flex-col" >
               <div>
                  <label for="email" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Email</label>
                  <input name="email" ref={emailRef} class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                </div>
        
                <div>
                  <label for="password" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Password</label>
                  <input name="password" ref={passwordRef}class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                </div> </div>):isphone == true ?(
              <div>
                  <label for="phone" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Phone</label>
                  <input name="phone" ref={phoneRef} class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                </div>              ):(<h1></h1>)}
               
                {isemailotp == true ?(   <div class="flex flex-col" >
                <span class="relative bg-white text-sm text-gray-400">Enter the otp sent to {email}</span>
                <label for="phoneotp" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">OTP</label>
               
               <input name="phoneotp" ref={otpRef} class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring my-5" />
            
               {loading == true? ( <button class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">loading...</button>):( <button  onClick={() => setisphoneotpfill(false,setisemailotpfill(true))} type="submit" class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">Complete Registration</button>)}
              </div>):isphoneotp == true ?(   <div class="flex flex-col" >
                <span class="relative bg-white text-sm text-gray-400">Enter the otp sent to {phone}</span>
                <label for="otp" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">OTP</label>
               
               <input name="otp" ref={phoneotpRef} class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring my-5" />
            
               {loading == true? ( <button class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">loading...</button>):( <button  onClick={() => setisemailotpfill(false,setisphoneotpfill(true))} type="submit" class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">Complete Registration</button>)}
              </div>):(
                <>
                {loading == true? ( <button class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">loading...</button>):(<button type="submit" class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">Register</button>)}
                </>
              )}
                
         
            </form>
            {isemail == true ?( 
        <div class="">
          <div class="mx-auto max-w-lg">
          <div class="flex flex-col gap-4 p-4 md:p-8" >
          
            <div class="relative flex items-center justify-center">
                  <span class="absolute inset-x-0 h-px bg-gray-300"></span>
                  <span class="relative bg-white px-4 text-sm text-gray-400">Register in with social</span>
                </div>
        
                <button  onClick={() => setisphone(true,setisemail(false),setisemailotp(false),setisphoneotp(false),setLoading(false))} class="flex items-center justify-center gap-2 rounded-lg bg-blue-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-blue-300 transition duration-100 hover:bg-blue-600 focus-visible:ring active:bg-blue-700 md:text-base">
                <svg class="h-5 w-5 shrink-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z" fill="#fbfcfb"></path> </g></svg>                  Continue with Phone
                </button>
              
              {loading == true? ( <button class="block rounded-lg bg-gray-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">loading...</button>):( <button onClick={() => login()} class="flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white px-8 py-3 text-center text-sm font-semibold text-gray-800 outline-none ring-gray-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:text-base">
              <svg class="h-5 w-5 shrink-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.7449 12.27C23.7449 11.48 23.6749 10.73 23.5549 10H12.2549V14.51H18.7249C18.4349 15.99 17.5849 17.24 16.3249 18.09V21.09H20.1849C22.4449 19 23.7449 15.92 23.7449 12.27Z" fill="#4285F4" />
                <path d="M12.2549 24C15.4949 24 18.2049 22.92 20.1849 21.09L16.3249 18.09C15.2449 18.81 13.8749 19.25 12.2549 19.25C9.12492 19.25 6.47492 17.14 5.52492 14.29H1.54492V17.38C3.51492 21.3 7.56492 24 12.2549 24Z" fill="#34A853" />
                <path d="M5.52488 14.29C5.27488 13.57 5.14488 12.8 5.14488 12C5.14488 11.2 5.28488 10.43 5.52488 9.71V6.62H1.54488C0.724882 8.24 0.254883 10.06 0.254883 12C0.254883 13.94 0.724882 15.76 1.54488 17.38L5.52488 14.29Z" fill="#FBBC05" />
                <path d="M12.2549 4.75C14.0249 4.75 15.6049 5.36 16.8549 6.55L20.2749 3.13C18.2049 1.19 15.4949 0 12.2549 0C7.56492 0 3.51492 2.7 1.54492 6.62L5.52492 9.71C6.47492 6.86 9.12492 4.75 12.2549 4.75Z" fill="#EA4335" />
              </svg>
              Continue with Google
            </button> )}
  
            </div> 
            </div>  
          </div>) : isphone == true ?( 
        <div class="">
          <div class="mx-auto max-w-lg">
          <div class="flex flex-col gap-4 p-4 md:p-8" >
          
            <div class="relative flex items-center justify-center">
                  <span class="absolute inset-x-0 h-px bg-gray-300"></span>
                  <span class="relative bg-white px-4 text-sm text-gray-400">Register in with social</span>
                </div>
        
                <button  onClick={() => setisphone(false,setisemail(true),setisemailotp(false),setisphoneotp(false),setLoading(false))} class="flex items-center justify-center gap-2 rounded-lg bg-blue-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-blue-300 transition duration-100 hover:bg-blue-600 focus-visible:ring active:bg-blue-700 md:text-base">
                <svg class="h-5 w-5 shrink-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 5.25L3 6V18L3.75 18.75H20.25L21 18V6L20.25 5.25H3.75ZM4.5 7.6955V17.25H19.5V7.69525L11.9999 14.5136L4.5 7.6955ZM18.3099 6.75H5.68986L11.9999 12.4864L18.3099 6.75Z" fill="#fbfcfb"></path> </g></svg>                  Continue with Email
                </button>
              
              {loading == true? ( <button class="block rounded-lg bg-gray-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">loading...</button>):( <button onClick={() => login()} class="flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white px-8 py-3 text-center text-sm font-semibold text-gray-800 outline-none ring-gray-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:text-base">
              <svg class="h-5 w-5 shrink-0" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.7449 12.27C23.7449 11.48 23.6749 10.73 23.5549 10H12.2549V14.51H18.7249C18.4349 15.99 17.5849 17.24 16.3249 18.09V21.09H20.1849C22.4449 19 23.7449 15.92 23.7449 12.27Z" fill="#4285F4" />
                <path d="M12.2549 24C15.4949 24 18.2049 22.92 20.1849 21.09L16.3249 18.09C15.2449 18.81 13.8749 19.25 12.2549 19.25C9.12492 19.25 6.47492 17.14 5.52492 14.29H1.54492V17.38C3.51492 21.3 7.56492 24 12.2549 24Z" fill="#34A853" />
                <path d="M5.52488 14.29C5.27488 13.57 5.14488 12.8 5.14488 12C5.14488 11.2 5.28488 10.43 5.52488 9.71V6.62H1.54488C0.724882 8.24 0.254883 10.06 0.254883 12C0.254883 13.94 0.724882 15.76 1.54488 17.38L5.52488 14.29Z" fill="#FBBC05" />
                <path d="M12.2549 4.75C14.0249 4.75 15.6049 5.36 16.8549 6.55L20.2749 3.13C18.2049 1.19 15.4949 0 12.2549 0C7.56492 0 3.51492 2.7 1.54492 6.62L5.52492 9.71C6.47492 6.86 9.12492 4.75 12.2549 4.75Z" fill="#EA4335" />
              </svg>
  
              Continue with Google
            </button> )}
  
            </div> 
            </div>  
          </div>) : 
          <div >
    
          </div>}
            </div> 
            </div>  
          </div>
      
        </div>
        </>
     ):count == 8 ? (
   
      <section class="flex flex-col items-center mx-3">
  

      <div class="flex flex-col items-center pb-0 pt-8 text-center sm:pb-16 lg:pb-32 lg:pt-32">
       <div> 
       <h5 class="mb-4  text-indigo-500">Way to go!</h5>
        </div>

        <h1 class="mb-8 text-4xl font-bold text-black sm:text-5xl md:mb-12 md:text-5xl">You’re now part of your Alumni network. </h1>

        <p class="mb-8 leading-relaxed text-gray-600 md:mb-12 xl:text-lg">Download the Alumates App to say hello.</p>

        <div class="flex w-full  gap-2.5 flex-row justify-center">
        <button onClick={() => {window.open("https://apps.apple.com/ng/app/alumate/id1532872038", "_blank"); }}  class="mx-2 flex items-center justify-center gap-2 rounded-lg bg-indigo-500  px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-blue-300 transition duration-100 hover:bg-blue-600 focus-visible:ring active:bg-blue-700 md:text-base">
        <svg viewBox="0 0 384 512" width="30" >
                    <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                </svg>     
                  Get on Apple Store
        </button>
               
        <button  onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.dreamlabs.alumates", "_blank"); }} class="flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white px-8 py-3 text-center text-sm font-semibold text-gray-800 outline-none ring-gray-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:text-base">
               
        <svg viewBox="30 336.7 120.9 129.2" width="30">
                    <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"/>
                    <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"/>
                    <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"/>
                    <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"/>
                </svg>   
                  Get on Playstore
        </button>
          {/* <a href="#" class="inline-block rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">Get on Playstore</a>
          <a href="#" class="inline-block rounded-lg border bg-white px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-100 focus-visible:ring active:bg-gray-200 md:text-base">Get on Apple Store</a> */}
        </div>
      </div>
    </section>
 ) :
    
        ( 
          <h1 className="text-center my-5"></h1>
       )}
      { count ==0?(
      <div class ='mx-3 my-5 bg-gray-100 p-6'>
       {primary.length < 1 ? (
               <h1 className="text-center my-5"></h1>
        ) : 
             (
              primary.map((img, index) => (
                 <>
                 {index ==0 ?(<><div class="text-left font-bold text-indigo-500 text-1xl my-2">PRIMARY SCHOOL</div>  <hr class="hr my-5" /></>):(<h1></h1>)}

                 <div>
                 {/* <button  onClick={() => setCount(6)} class="flex w-full flex-col my-1 rounded-lg bg-white px-8 py-3 text-center text-sm font-semibold  text-gray-800 outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-400 focus-visible:ring active:bg-indigo-700 md:text-base">{img.name}</button>
                 <hr class="h-px my-1 border-0 dark:bg-gray-700"></hr> */}
            <div class="flex  items-center gap-2 flex-row md:gap-3 my-3">
            <div>
            {/* <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"/></svg> */}
            <div class="text-left text-sm  text-indigo-400 "> * {img.name}, {img.city}</div>
           </div>
             </div>
             {/* <hr class="hr my-5" /> */}
           <NavLink to = {"/primary"}>
          <div>
          {index ==3 ?(<><div class="text-right font-bold text-indigo-500 text-1xl my-2">More</div> <hr class="hr my-5" /></>):(<h1></h1>)}
          </div>
          </NavLink>

                </div>
                </>
               ))
             )}   
                {secondary.length < 1 ? (
               <h1 className="text-center my-5"></h1>
             ) : 
             (
              secondary.map((img, index) => (
                 <>
             {index ==0 ?(<><div class="text-left font-bold text-indigo-500 text-1xl my-2">SECONDARY SCHOOL / HIGH SCHOOL</div>  <hr class="hr my-5" /></>):(<h1></h1>)}
 
                 <div>
                  
                 {/* <button  onClick={() => setCount(6)} class="flex w-full flex-col my-1 rounded-lg bg-white px-8 py-3 text-center text-sm font-semibold  text-gray-800 outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-400 focus-visible:ring active:bg-indigo-700 md:text-base">{img.name}</button>
                 <hr class="h-px my-1 border-0 dark:bg-gray-700"></hr> */}
            <div class="flex  items-center gap-2 flex-row md:gap-3 my-3">
            <div>
            <div class="text-left text-sm  text-indigo-400  ">* {img.name}, {img.city}</div>
           </div>
             </div>
             {/* <hr class="hr my-5" /> */}
             <NavLink to = {"/secondary"}>
          <div>
          {index ==3 ?(<><div class="text-right font-bold text-indigo-500 text-1xl my-2">More</div> <hr class="hr my-5" /></>):(<h1></h1>)}
          </div>
          </NavLink>
                </div>
                </>
               ))
             )} 
             {uni.length < 1 ? (
               <h1 className="text-center my-5"></h1>
             ) : 
             (
              uni.map((img, index) => (
                 <>
                 {index ==0 ?(<><div class="text-left font-bold text-indigo-500 text-1xl my-2">TERTIARY INSTITUTION</div>  <hr class="hr my-5" /></>):(<h1></h1>)}
                 <div>
                 {/* <button  onClick={() => setCount(6)} class="flex w-full flex-col my-1 rounded-lg bg-white px-8 py-3 text-center text-sm font-semibold  text-gray-800 outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-400 focus-visible:ring active:bg-indigo-700 md:text-base">{img.name}</button>
                 <hr class="h-px my-1 border-0 dark:bg-gray-700"></hr> */}
            <div class="flex  items-center gap-2 flex-row md:gap-3 my-3">
            <div>
            <div class="text-left text-sm  text-indigo-400 ">* {img.name}, {img.city}</div>
           </div>
             </div>
             {/* <hr class="hr my-5" /> */}
             <NavLink to = {"/tertiary"}>
          <div>
          {index ==3 ?(<><div class="text-right font-bold text-indigo-500 text-1xl my-2">More</div> <hr class="hr my-5" /></>):(<h1></h1>)}
          </div>
          </NavLink>
                </div>
                </>
               ))
             )} 
      </div>):(<h1></h1>)}
    <div class="bg-gray-900 mt-12">
  <footer class="mx-auto max-w-screen-2xl px-4 md:px-8">
   <div class='h-10'></div>
    <div class="col-span-full lg:col-span-2">
        <div class="mb-4">
          <a href="/" class="inline-flex items-center gap-2 text-xl font-bold text-white md:text-2xl" aria-label="logo">
          <img src={logo} width="25" height="25"  />
            Alumates
          </a>
        </div>

        <p class="mb-6 text-gray-500 sm:pr-8">Building Africa's Largest Socio Business Hub for Alumni and Classmates.</p>

     
      </div>

    <div class="mb-16 grid grid-cols-2 gap-12 pt-10 md:grid-cols-4 lg:grid-cols-6 lg:gap-8 lg:pt-12">
      <div class="col-span-full lg:col-span-2">
      <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Download App</div>
        <div class="">
        <button onClick={() => {window.open("https://apps.apple.com/ng/app/alumate/id1532872038", "_blank"); }}  class="my-4 flex items-center justify-center gap-2 rounded-lg border bg-gray-900 w-40 py-1 text-center text-sm font-semibold text-white outline-none ring-blue-300 transition  focus-visible:ring active:bg-blue-700 md:text-base">
        <svg viewBox="0 0 384 512" width="20" >
         <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
        </svg>  
        <div class = 'flex-col sm:flex-row'>
        <p class=" text-left text-gray-500">Get on</p>
        <h2 class = 'text-left font-bold'>AppleStore</h2> 
        </div>
        </button>
               
        <button  onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.dreamlabs.alumates", "_blank"); }} class="flex items-center justify-center gap-2 rounded-lg border w-40 py-1 bg-gray-900 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition focus-visible:ring active:bg-gray-200 md:text-base">
               
        <svg viewBox="30 336.7 120.9 129.2" width="20">
                    <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"/>
                    <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"/>
                    <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"/>
                    <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"/>
                </svg>   
                <div class = 'flex-col sm:flex-row'>
        <p class=" text-left text-gray-500">Get on</p>
        <h2 class = 'text-left font-bold'>PlayStore</h2> 
        </div>
        </button>
        </div>
      </div>


      <div>
        <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Company</div>

        <nav class="flex flex-col gap-4">
          <NavLink to = {"/about"}>
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">About</a>
          </div>
          </NavLink>


          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Jobs</a>
          </div>

          <NavLink to = {"/add-school"}>
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Add School</a>
          </div>
            </NavLink>
          
        </nav>
      </div>

      <div>
        <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Support</div>

        <nav class="flex flex-col gap-4">
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Contact</a>
          </div>

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Documentation</a>
          </div>

    

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">FAQ</a>
          </div>
        </nav>
      </div>

      <div>
        <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Legal</div>

        <nav class="flex flex-col gap-4">
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Terms of Service</a>
          </div>

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Privacy Policy</a>
          </div>

  
        </nav>
      </div>
    </div>

    <div class="border-t border-gray-800 py-8 text-center text-sm text-gray-400">© 2023.  All rights reserved.</div>
    <div class="justify-center pb-8  flex gap-4">
          <a href="#" target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </a>

          <a href="#" target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </a>

          <a href="#" target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>

          <a href="#" target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
            </svg>
          </a>
        </div>
  </footer>
  
</div>
       
    </>
  );
}


export default JoinSchool;

