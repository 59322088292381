import React, { useEffect, useRef, useState } from "react";
import { firestore } from "../API/firebase";
import { supabase } from '../supabaseClient';
import { Scrollbar } from "smooth-scrollbar-react";
import ProgressBar from "@ramonak/react-progress-bar";
import axios from 'axios';
import emailjs from '@emailjs/browser';
import { NavLink } from 'react-router-dom'
import { useParams } from "react-router-dom";









function AddSchool() {
React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
const randomNumberInRange = (max) => {
        return  Math.floor(Math.random() * max);
 };
  const [count, setCount] = useState(0);


  const [otp, setotp] = useState(0);
  const [isemail, setisemail] = useState(false);
  const [loading, setLoading] = useState(false);




  const emailRef = useRef();
  const otpRef = useRef();
  const passwordRef = useRef();
  const firstnameRef = useRef();
  const lastnamenameRef = useRef();
  const CountryRef = useRef();
  const StateRef = useRef();
  const CityRef = useRef();
  const SchoolnameRef = useRef();
  const scrollbar = useRef(null);
  const [graduatetype, setGradutetype] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentstatus, setCurrentStatus] = useState('Pirmary')
  const [currentstate, setCurrentState] = useState('Abia')

 


  async function handleSubmit(e) {

    e.preventDefault();
    const firstName = firstnameRef.current.value
    const lastName = lastnamenameRef.current.value
    const email = emailRef.current.value
    const county = CountryRef.current.value
    const city = CityRef.current.value
    const schoolname = SchoolnameRef.current.value
    var id = randomNumberInRange(1234567);
   
    setLoading(true);
    await firestore
    .collection("newschoolsetup")
    .doc(`${id}`)
    .set({
        
        "school_name": schoolname,
        'firstname': firstName,
        'lastname': lastName,
        'email':email,
        "city":city,
        'documentsid': id,
        "status": currentstatus,
        "state": currentstate ,
        "country": county,
        'from':'web'
    });
    setCount(1);
    // @TODO: add login logic
  }

  const changeFruit = (newFruit) => {
    setCurrentStatus(newFruit)
    
  }

  const changestate = (newFruit) => {
    setCurrentState(newFruit)
    
  }


  return (
    <>
    
  <header class="mx-5 flex items-center justify-between py-4 md:mb-12 md:py-8 xl:mb-16 ">
      <a href="/" class="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-2xl" aria-label="logo">
        Alumates
      </a>
      {/* <a  class="hidden rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base lg:inline-block">Join an Alumni</a>
      <button type="button" class="inline-flex items-center gap-2 rounded-lg bg-gray-200 px-2.5 py-2 text-sm font-semibold text-gray-500 ring-indigo-300 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base lg:hidden">
        Join an Alumni
      </button> */}
    </header>
   
  
    { count == 8 ?(<h1></h1>):(<section class="flex flex-col items-center mx-5">
    
    <div class="flex max-w-xl flex-col items-center pb-0 pt-5 text-center sm:pb-10 lg:pb-25 lg:pt-25">

    { count ==0 ? (  <h1 class="mb-8 text-4xl font-bold text-black sm:text-5xl md:mb-8 md:text-5xl">School Setup</h1>):(<h1></h1>)}
      {/* <p class="mb-8 leading-relaxed text-gray-500 md:mb-8 xl:text-lg">Regis.</p> */}

    </div>

    
  </section>)}

  
          <>
          { count ==0 ? (
          <div class="bg-white ">
             <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div class="mx-auto max-w-lg rounded-lg border">
          <div class="flex flex-col gap-4 p-4 md:p-8" >
            <form onSubmit={handleSubmit} class="flex flex-col gap-4" >
            
              <div>
            <label for="first-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">First Name</label>
            <input name="first-name" ref={firstnameRef} class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
            </div>

             <div>
               <label for="last-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Last Name</label>
               <input name="last-name" ref={lastnamenameRef} class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
               </div>
                <div>
                  <label for="email" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Email</label>
                  <input name="email" ref={emailRef} class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                </div>
                <label for="first-name" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Type of Institution</label>
                  <div class="relative">
                    <select  onChange={(event) => changeFruit(event.target.value)} value={currentstatus}  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                      <option value="Primary">Pirmary</option>
                      <option value="Secondary">Secondary</option>
                      <option value="Tertiary">Tertiary</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                        <div>
                  <label for="country" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">County of Institution</label>
                  <input name="country" ref={CountryRef}class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                </div>
                <div>
                  <label for="state" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">State of Institution</label>
                  <div class="relative">
                    <select  onChange={(event) => changestate(event.target.value)} value={currentstate}  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    
                      <option value="Abia">Abia</option>
                      <option value="Adamawa">Adamawa</option>
                      <option value="Akwa Ibom">Akwa Ibom</option>
                      <option value="Anambra">Anambra</option>
                      <option value="Bauchi">Bauchi</option>
                      <option value="Benue">Benue</option>
                      <option value="Borno">Borno</option>
                      <option value="Cross River">Cross River</option>
                      <option value="Delta">Delta</option>
                      <option value="Ebonyi">Ebonyi</option>
                      <option value="Edo">Edo</option>
                      <option value="Ekiti">Ekiti</option>
                      <option value="Enugu">Enugu</option>
                      <option value="Federal Capital Territory">Federal Capital Territory</option>
                      <option value="Gombe">Gombe</option>
                      <option value="Imo">Imo</option>
                      <option value="Jigawa">Jigawa</option>
                      <option value="Kaduna">Kaduna</option>
                      <option value="Kano">Kano</option>
                      <option value="Katsina">Katsina</option>
                      <option value="Kebbi">Kebbi</option>
                      <option value="Kogi">Kogi</option>
                      <option value="Kwara">Kwara</option>
                      <option value="Lagos">Lagos</option>
                      <option value="Nasarawa">Nasarawa</option>
                      <option value="Niger">Niger</option>
                      <option value="Ogun">Ogun</option>
                      <option value="Ondo">Ondo</option>
                      <option value="Osun">Osun</option>
                      <option value="Oyo">Oyo</option>
                      <option value="Plateau">Plateau</option>
                      <option value="Rivers">Rivers</option>
                      <option value="Sokoto">Sokoto</option>
                      <option value="Taraba">Taraba</option>
                      <option value="Yobe">Yobe</option>
                      <option value="Zamfara">Zamfara</option>

                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                </div>
                <div>
                  <label for="city" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">City of Institution</label>
                  <input name="city" ref={CityRef}class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                </div>
                <div>
                  <label for="schoolname" class="mb-2 inline-block text-sm text-gray-800 sm:text-base">Name of Institution</label>
                  <input name="schoolname" ref={SchoolnameRef}class="w-full rounded border bg-gray-50 px-3 py-2 text-gray-800 outline-none ring-indigo-300 transition duration-100 focus:ring" />
                </div>
                {loading == true? ( <button class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">loading...</button>):( <button type="submit" class="block rounded-lg bg-gray-800 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-gray-300 transition duration-100 hover:bg-gray-700 focus-visible:ring active:bg-gray-600 md:text-base">Registration Alumni</button>)}

                
         
            </form>
     
            </div> 
            </div>  
          </div>
      
        </div>):(
         <section class="flex flex-col items-center mx-3">
  
        <svg class="h-40 w-40 text-green-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="20 6 9 17 4 12" /></svg>
         <div class="flex max-w-xl flex-col items-center pb-0 pt-8 text-center sm:pb-16 lg:pb-32 lg:pt-32">
           <p class="mb-4 font-semibold text-indigo-500 md:mb-6 md:text-lg xl:text-xl">Way to go!</p>
   
           <h1 class="mb-8 text-4xl font-bold text-black sm:text-5xl md:mb-12 md:text-4xl">We are processing your Alumni registration  </h1>
           <p class="mb-8 leading-relaxed text-gray-600 md:mb-12 xl:text-lg">We will send you an email once you school is available.</p>

       
         </div>
       </section>
        )
         }    
        </>
     
       
    <div class="bg-gray-900 mt-12">
  <footer class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="mb-16 grid grid-cols-2 gap-12 pt-10 md:grid-cols-4 lg:grid-cols-6 lg:gap-8 lg:pt-12">
      <div class="col-span-full lg:col-span-2">
        <div class="mb-4 lg:-mt-2">
          <a href="/" class="inline-flex items-center gap-2 text-xl font-bold text-gray-100 md:text-2xl" aria-label="logo">
            Alumates
          </a>
        </div>

        <p class="mb-6 text-gray-400 sm:pr-8">Building Africa's Largest Socio Business Hub for Alumni and Classmates.</p>

        <div class="flex gap-4">
          <a  target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </a>

          <a  target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </a>

          <a  target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>

          <a  target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
            </svg>
          </a>
        </div>
      </div>


      <div>
        <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Company</div>

        <nav class="flex flex-col gap-4">
         
          <NavLink to = {"/about"}>
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">About</a>
          </div>
          </NavLink>

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Jobs</a>
          </div>

          <NavLink to = {"/add-school"}>
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Add School</a>
          </div>
          </NavLink>

        </nav>
      </div>

      <div>
        <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Support</div>

        <nav class="flex flex-col gap-4">
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Contact</a>
          </div>

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Documentation</a>
          </div>

    

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">FAQ</a>
          </div>
        </nav>
      </div>

      <div>
        <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Legal</div>

        <nav class="flex flex-col gap-4">
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Terms of Service</a>
          </div>

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Privacy Policy</a>
          </div>

  
        </nav>
      </div>
    </div>

    <div class="border-t border-gray-800 py-8 text-center text-sm text-gray-400">© 2023.  All rights reserved.</div>
  </footer>
  
</div>
       
    </>
  );
}
export default AddSchool;

