import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDH9cMUE8SfhNtnTzzAWAHp432zsKaMXGs",
  authDomain: "alumates-1cf81.firebaseapp.com",
  databaseURL: "https://alumates-1cf81.firebaseio.com",
  projectId: "alumates-1cf81",
  storageBucket: "alumates-1cf81.appspot.com",
  messagingSenderId: "656424583757",
  appId: "1:656424583757:web:c8edf1ca8648d1c5d29204",
  measurementId: "G-GQ6RJ009T0"
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const storage = firebase.storage();
