import React  from 'react';
import {Routes, Route} from "react-router-dom";
import About from "./pages/about";
import AddSchool from "./pages/add_school";
import Home from "./pages/home";
import PrimarySchool from './pages/primary';
import SecondarySchool from './pages/secondary';
import TertiarySchool from './pages/tertiary';
import SchoolDetails from './pages/school_details';
import JoinSchool from './pages/join_school';


function App() {
  return (
    <>

    <Routes >
      <Route path="/" element={ <Home /> } />
      <Route path="/about" element={ <About /> } />
      <Route path="/add-school" element={ <AddSchool /> } />
      <Route path="/primary" element={ <PrimarySchool /> } />
      <Route path="/secondary" element={ <SecondarySchool /> } />
      <Route path="/tertiary" element={ <TertiarySchool/> } />
      <Route path="/school/details/:id/:name/:city/:status/" element={ <SchoolDetails/> } />
      <Route path="/school/join/:id/:counts/:name/:status/" element={ <JoinSchool/> } />


    </Routes>
    </>
  );
}

export default App;
