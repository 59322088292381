import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../API/firebase";
import { NavLink } from 'react-router-dom'
import SchoolLogoCard from './school_logo_card';
import ClassCard from '../components/class_card';

import ImageCard from '../components/image_card';








function SchoolDetails() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let parms = useParams();

  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const CountryRef = useRef();
  const StateRef = useRef();
  const CityRef = useRef();
  const SchoolnameRef = useRef();
  const [getingdata, setgetingdata] = useState(true);
  const [graduatetype, setGradutetype] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [membercontacts, setmemberContacts] = useState([]);
  const [memberlistcontacts, setmemberlistContacts] = useState([]);

  const [schoolmembercontacts, setschoolmemberContacts] = useState([]);

 
  


//  useEffect(() => {
//     const fetchprimaryData = async () => {
//       setgetingdata(true);
//       const data = await firestore.collection("mainschoolinfo")
//       .doc(`${parms.id}`)
//       .collection("info")
//       .doc(`${parms.id}`)
//       .get().then((res) =>{
//         if(res.size==0){
          
//             // alert('yes');
//         }
//         else{
//              //Collection does exist
//             //  alert('no');
//         }
 
//        });
//     };

//     fetchprimaryData();
//   }, []);

  
useEffect(() => {
  const handledegreeChange = async (e) => {

      const data = await firestore .collection("mainschoolinfo")
      .doc(`${parms.id}`)
      .collection('info')
      .limit(1)
      // .where("id", "==", `${product}`)
      .get();
      setmemberContacts(data.docs.map((doc) => ({ ...doc.data() })));
      
      // var allsetresponse = await supabase
//         .from("allset")

//    .select("*")
//    .eq('search_name', `${seachname}`)
//      .single();   
  };

  handledegreeChange(parms.id);
}, [parms.id]);

useEffect(() => {
  const handledegreeChange = async (e) => {

      const data = await firestore .collection("alumni")
      .doc(`${parms.id}`)
      .collection('members')
      .get();
      setschoolmemberContacts(data.docs.map((doc) => ({ ...doc.data() })));

      
      
      // var allsetresponse = await supabase
//         .from("allset")

//    .select("*")
//    .eq('search_name', `${seachname}`)
//      .single();   
  };

  handledegreeChange(parms.id);
}, [parms.id]);

useEffect(() => {
  const handledegreeChange = async (e) => {

      const data = await firestore .collection("alumni")
      .doc(`${parms.id}`)
      .collection('members')
      .limit(6)
      .get();
      setmemberlistContacts(data.docs.map((doc) => ({ ...doc.data() })));

      
      
      // var allsetresponse = await supabase
//         .from("allset")

//    .select("*")
//    .eq('search_name', `${seachname}`)
//      .single();   
  };

  handledegreeChange(parms.id);
}, [parms.id]);
  return (
    <>
    
  <header class="mx-5 flex items-center justify-between py-4 md:mb-12 md:py-8 xl:mb-16 ">
      <a href="/" class="inline-flex items-center gap-2.5 text-2xl font-bold text-black md:text-2xl" aria-label="logo">
        Alumates
      </a>
      {/* <a  class="hidden rounded-lg bg-gray-200 px-8 py-3 text-center text-sm font-semibold text-gray-500 outline-none ring-indigo-300 transition duration-100 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base lg:inline-block">Join an Alumni</a>
      <button type="button" class="inline-flex items-center gap-2 rounded-lg bg-gray-200 px-2.5 py-2 text-sm font-semibold text-gray-500 ring-indigo-300 hover:bg-gray-300 focus-visible:ring active:text-gray-700 md:text-base lg:hidden">
        Join an Alumni
      </button> */}
    </header>
   
  
      <main className="profile-page">
        <section className="relative block" style={{ height: "500px" }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://firebasestorage.googleapis.com/v0/b/alumates-1cf81.appspot.com/o/school.jpeg?alt=media&token=c6b9ffb9-b369-48f5-9069-1d8532fbd346')"
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-5">
          <div className="container mx-auto px-4 mt-5">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative mt-5">
                    {membercontacts.map((products) => (
                          <>
                      <div class="mt-10 h-24 w-24 overflow-hidden rounded-full bg-gray-200 shadow-lg md:mb-4 md:h-32 md:w-32">
                      {/* <h1>{products.photo??''} ddjvdd</h1>        */}
                      <img src={products.photo??''} loading="lazy" alt="" class="h-full w-full object-cover object-center"/>  
                            </div>
                      </>
                        ))}
                    
                    </div>
                  </div>
               
                </div>
                <div class="bg-white py-6 sm:py-8 lg:py-12">

  <div class="mx-auto max-w-screen-xl px-4 md:px-8">
    <div class="mb-8 mt-5">
      <h2 class="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">{parms.name}</h2>
      <p class="mx-auto max-w-screen-md text-center text-indigo-500 md:text-lg">{parms.status} School</p>

      <p class="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">{parms.city}</p>
    </div>
  <div style={{ borderTop: "0.5px solid #000 ", marginLeft: 20, marginRight: 20, marginTop:20 ,marginBottom: 20}}></div>
        {/* <p class="mb-8 leading-relaxed text-gray-500 md:mb-8 xl:text-lg">Regis.</p> */}

        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
  <div class="mb-10 md:mb-16">
    <h2 class="mb-4 text-left text-2xl font-bold text-black  md:mb-6 lg:text-2xl">About</h2>

    <p class="mb-4 text-left   text-gray-500">{parms.name}  is a {parms.status} School, located in {parms.city}, Nigeria, It was established in the year ---, and  has about {schoolmembercontacts.length} Available Members.</p>
  </div>
 
</div>
<div style={{ borderTop: "0.5px solid #000 ", marginLeft: 20, marginRight: 20, marginTop:20 ,marginBottom: 20}}></div>
<div >
  <div class="mx-auto max-w-screen-2xl">
    <div class="flex  items-center justify-between gap-4 rounded-lg p-4 flex-row md:p-8">
      <div>
      <h2 class="mb-4 text-left text-2xl font-bold text-black  md:mb-6 lg:text-2xl">Members</h2>
      <p class="mb-4 text-left   text-gray-500">  {schoolmembercontacts.length} Available Members</p>
      </div>

    { parms.status =="Tertiary"?(<NavLink to = {`/school/join/${parms.id}/1/${parms.name}/${parms.status}`}>
           <div class="flex w-full flex-col sm:flex-row sm:justify-center mt-2">
                   <a class="inline-block rounded-lg bg-indigo-400 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">Join</a>
                 </div>
           </NavLink>):(<NavLink to = {`/school/join/${parms.id}/4/${parms.name}/${parms.status}`}>
           <div class="flex w-full flex-col sm:flex-row sm:justify-center mt-2">
                   <a class="inline-block rounded-lg bg-indigo-400 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base">Join</a>
                 </div>
           </NavLink>)}
    </div>
  </div>
</div>
<div class="mx-auto max-w-screen-2xl px-4 md:px-8">
  
 
  <div class="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 lg:gap-8">
      {memberlistcontacts.length < 1 ? (
        <div class="mb-10 md:mb-16">
        <h2 class="mb-4 text-center text-2xl font-bold text-black  md:mb-6 lg:text-2xl"></h2>
    
      </div>
      ) : 
      (
        memberlistcontacts.map((img, index) => (
          <>
     
    {
   (  <div class="flex flex-col items-center rounded-lg bg-gray-100 p-4 lg:p-8">
       {/* <div class="mb-2 h-24 w-24 overflow-hidden rounded-full bg-gray-200 shadow-lg md:mb-4 md:h-32 md:w-32">
         <img src="https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?auto=format&q=75&fit=crop&w=256" loading="lazy" alt="Photo by Matheus Ferrero" class="h-full w-full object-cover object-center" />
       </div> */}
        <ImageCard product={img.user_id??"0"} /> 

       <div>
         <div class="text-center font-bold text-indigo-500 md:text-lg">{img.name}</div>
         <p class="mb-3 text-center text-sm text-gray-500 md:mb-4 md:text-base">{img.profession}</p>
         <ClassCard product={img.group_id??"0"} />

       </div>
     </div>)}
         </>
        ))
      )}
      </div>
</div>
  </div>
</div>
                {/* <div style={{ borderTop: "1px solid #000 ", marginLeft: 20, marginRight: 20, marginTop:20 }}></div>
                <div className="text-center mt-12">
                  <h3 className="text-3xl font-semibold leading-normal mb-2 text-gray-800 ">
                    {parms.name}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                    {parms.city}
                  </div>
                  <div className="mb-2 text-gray-700 mt-10">
                    <i className="fas fa-briefcase mr-2 text-lg text-gray-500"></i>
                    Established - 
                  </div>
                </div>
                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-gray-800">
                        An artist of considerable range, Jenna the name taken by
                        Melbourne-raised, Brooklyn-based Nick Murphy writes,
                        performs and records all of his own music, giving it a
                        warm, intimate feel with a solid groove structure. An
                        artist of considerable range.
                      </p>
                      <a
                        href="#pablo"
                        className="font-normal text-pink-500"
                        onClick={e => e.preventDefault()}
                      >
                        Edit info
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </main>

  
          <>
         
         <section class="flex flex-col items-center mx-2">
         <div class="flex max-w-xl flex-col items-center pb-0 pt-8 text-center sm:pb-16 lg:pb-32 lg:pt-15">   
        
         </div>
       </section>
        
        </>
     
       
    <div class="bg-gray-900 mt-12">
  <footer class="mx-auto max-w-screen-2xl px-4 md:px-8">
    <div class="mb-16 grid grid-cols-2 gap-12 pt-10 md:grid-cols-4 lg:grid-cols-6 lg:gap-8 lg:pt-12">
      <div class="col-span-full lg:col-span-2">
        <div class="mb-4 lg:-mt-2">
          <a href="/" class="inline-flex items-center gap-2 text-xl font-bold text-gray-100 md:text-2xl" aria-label="logo">
            Alumates
          </a>
        </div>

        <p class="mb-6 text-gray-400 sm:pr-8">Building Africa's Largest Socio Business Hub for Alumni and Classmates.</p>

        <div class="flex gap-4">
          <a  target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </a>

          <a  target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </a>

          <a  target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>

          <a  target="_blank" class="text-gray-400 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
            </svg>
          </a>
        </div>
      </div>


      <div>
        <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Company</div>

        <nav class="flex flex-col gap-4">
         
          <NavLink to = {"/about"}>
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">About</a>
          </div>
          </NavLink>

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Jobs</a>
          </div>

          <NavLink to = {"/add-school"}>
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Add School</a>
          </div>
          </NavLink>

        </nav>
      </div>

      <div>
        <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Support</div>

        <nav class="flex flex-col gap-4">
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Contact</a>
          </div>

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Documentation</a>
          </div>

    

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">FAQ</a>
          </div>
        </nav>
      </div>

      <div>
        <div class="mb-4 font-bold uppercase tracking-widest text-gray-100">Legal</div>

        <nav class="flex flex-col gap-4">
          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Terms of Service</a>
          </div>

          <div>
            <a  class="text-gray-400 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Privacy Policy</a>
          </div>

  
        </nav>
      </div>
    </div>

    <div class="border-t border-gray-800 py-8 text-center text-sm text-gray-400">© 2023.  All rights reserved.</div>
  </footer>
  
</div>
       
    </>
  );
}
export default SchoolDetails;

