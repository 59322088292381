import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/main.css';
import { BrowserRouter } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import { GoogleOAuthProvider } from '@react-oauth/google';
const root = ReactDOM.createRoot(document.getElementById('root'));

ReactDOM.render(
<GoogleOAuthProvider clientId="656424583757-dm8vneip7b0vf5fm4cvt1ru2jrvcgb51.apps.googleusercontent.com">
 <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
</GoogleOAuthProvider>,
 document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

