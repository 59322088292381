import React, { useEffect, useRef, useState } from "react";
import { supabase } from '../supabaseClient';


function ImageCard(props) {
    const product = props.product;
    const [membercontacts, setmemberContacts] = useState([]);
    const [groupname, setgroupname] = useState("");
  
    useEffect(() => {
        const handledegreeChange = async (e) => {
            try {
              const { data, error } = await supabase
                .from("user_info")
                .select("*")
                .eq('id', product)
                .limit(1)
              if (error) throw error;
              if (data != null) {
                setmemberContacts(data);
        
              }
            } catch (error) {
    
            }
            // var allsetresponse = await supabase
    //         .from("allset")

    //    .select("*")
    //    .eq('search_name', `${seachname}`)
    //      .single();   
        };
      
        handledegreeChange(product);
      }, [product]);
    return (
       <div>
        {membercontacts.map((products) => (
            <>
         <div class="mb-2 h-24 w-24 overflow-hidden rounded-full bg-gray-200 shadow-lg md:mb-4 md:h-32 md:w-32">
        <img src={products.profilepicture??''} loading="lazy" alt="" class="h-full w-full object-cover object-center"/>  
               </div>
        </>
          ))}
          </div>
    )
}

export default ImageCard;